import React, { memo, useCallback, useEffect, useState } from "react";
import "./autoRegularPayment.scss";
import Payment from "../../../component/Payment/Payment";
import CustomTable from "../../../component/customtable/CustomTable";
import apiCall from "../../../utils/apiFunction/apiCall";
import { commonVariables,dateFormats,paymentDropdownValues} from "../../../constants/Common/commonConstants";
import { Form, Modal, Button } from "react-bootstrap";
import {apiConstants,toastIds} from "../../../constants/Common/apiConstants";
import moment from "moment";
import SystemXLoader from "../../../component/common/Loader/SystemXLoader";
import { filter as _filter } from "lodash";
import { toast } from "react-toastify";
import Select from "react-select";
import { useSelector } from "react-redux";
import { errorMessages } from "../../../constants/Common/errorConstants";
import {attributePaymentConstants,tableNamePaymentConstants,} from "../../../constants/Common/apiConstants";

const AutoRegularPayment = () => {
  const [tableLoading, setTableLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [listdata, setListdata] = useState([]);
  const [isApiCall, setIsApiCall] = useState(true);
  const [count, setCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [showHDFCModal, setShowHDFCModal] = useState(false);
  const [showICICIModal, setShowICICIModal] = useState(false);
  const [showSBIModal, setShowSBIModal] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [regularICICISwitch, setRegularICICISwitch] = useState(false);
  const [regularHDFCSwitch, setRegularHDFCSwitch] = useState(false);
  const [regularSBISwitch, setRegularSBISwitch] = useState(false);
  const [autoDisbursementProductCode, setAutoDisbursementProductCode] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAutoDisbursementProductCode();
  }, []);

  let LoginState = useSelector((state) => state.login);
  const handleClose = () => {
    if (showICICIModal) { setShowICICIModal(false) }
    else if (showHDFCModal) {
      setShowHDFCModal(false)
    }
    else if (showSBIModal) {
      setShowSBIModal(false);
    }
  };

  const getSwitchBankValues = async () => {
    try {
      const res = await apiCall.post(apiConstants?.findData, {
        tablename: tableNamePaymentConstants?.BANK_MASTER_AUTOPAY,
        whereQuery: {
          isActive: 1,
        },
        attributes: ['bank_name', 'isAutoPay'],
      });
  
      if (res?.data?.status === 200) {
        const response = res?.data?.response;
        response.map((item) => {
          if (item.bank_name === "ICICI BANK") {
            setRegularICICISwitch(item.isAutoPay);
          } else if (item.bank_name === "HDFC DIGI CFAB") {
            setRegularHDFCSwitch(item.isAutoPay);
          } else if (item.bank_name === "SBI Retail CFAB Disb Account") {
            setRegularSBISwitch(item.isAutoPay)
          }
        });
      } else {
        toast.error(res?.data?.message, { toastId: toastIds?.DataError });
      }
    } catch (error) {
      toast.error(error?.message, { toastId: toastIds?.DataError });
    }
  };
  

  const Table = () => {
    return (
      <div
        className="RegularPayment_tableAlignment"
        style={listdata?.length <= 0 ? { display: "none" } : {}}
      >
        <CustomTable
          cols={[
            { id: "srNo", title: "#" },
            { id: "loanAccNo", title: "Loan Account No" },
            { id: "productDesc", title: "Product Description" },
            { id: "branch", title: "Branch Name" },
            { id: "journalNo", title: "Journal No / Payment Ref No" },
            { id: "disAmt", title: "Disbursement Amount" },
            { id: "cheque/dd no", title: "Cheque / DD No" },
            { id: "Cheque_DD_Date", title: "Cheque DD Date" },
            { id: "transactionDate", title: "Transaction Date" },
            { id: "applicantName", title: "Applicant Name" },
            { id: "payTo", title: "Pay to" },
            { id: "txnIMBSName", title: "Txn IMPS Bene Name" },
            { id: "beneNameScore", title: "Score %" },
            { id: "status", title: "Status" },
            { id: "beneBankName", title: "Bene Bank Name" },
            { id: "Bene_Bank_Acct", title: "Bene Bank Account" },
            { id: "ifscCode", title: "IFSC Code" },
            { id: "paymentMode", title: "Payment Mode" },
            { id: "businessId", title: "Business ID" },
            { id: "webTop", title: "Webtop" },
            { id: "caseStatus", title: "Case Status" },
            { id: "cas_no", title: "CAS_No" },
          ]}
          data={listdata}
          count={count}
          setPagination={(page) => changePage(page)}
          activePage={pageNumber}
          loading={tableLoading}
          updateLoading={updateLoading}
          isAutoRegularPayment
          isDisabled={isDisabled}
        />
      </div>
    );
  };

  const listApi = async (value, showErr) => {
    try {
      let temp = 0;
      let initialLimit = 300;
      if ((value || pageNumber) > 0) {
        temp = value ? (value - 1) * 300 : (pageNumber - 1) * 300;
      }
      let DataTmp = JSON.parse(localStorage.getItem(paymentDropdownValues));
      if (DataTmp) {
        setTableLoading(true);
        let parsedData = Object.assign({}, ...DataTmp);
        let paramObj = {
          offset: temp,
          limit: initialLimit,
          type: commonVariables?.Regular,
          company_code: parsedData["Company"] || "",
          sponsor_bank: parsedData["Sponsor Payment Bank"] || "",
          pay_to: parsedData["Vendor Type"]?.toLowerCase() || "",
          scheme_code: "",
          product_description: parsedData["Product Code"] || "",
          isAutoPay:"true"
        };
        if (parsedData["Sponsor Payment Bank"] === 'ICICI BANK') {
          setIsDisabled(true);
        } else {
          setIsDisabled(false);
        }
        const res = await apiCall.post(apiConstants?.fetchPayment, paramObj);
        if (res?.data?.status === 200) {
          setIsApiCall(true);
          let result = res?.data?.response?.result;
          if (result?.length) {
            result = result.map((item, index) => {
              let newItem = {
                srNo: index + 1,
                loanAccNo: item?.loan_no || "-",
                id: item?.id || "",
                productDesc: item?.product_description || "-",
                branch: item?.branch_name || "-",
                journalNo: item?.fund_reference_id || "-",
                disAmt: item?.disbursment_amount || "-",
                "cheque/dd no": item?.cheque_dd_no || "-",
                transactionDate: moment(item.createdAt).format(dateFormats?.dmyHms) || "-",
                applicantName: item?.applicant_name || "-",
                payTo: item?.pay_to || "-",
                txnIMBSName: item?.txn_imps_bene_name || "-",
                Cheque_DD_Date: moment(item.cheque_dd_date).format(dateFormats?.dmy) || "-",
                Bene_Bank_Acct: item?.bene_bank_acct || "-",
                beneNameScore: item?.bene_name_match_score || "-",
                status: item?.acct_validation_status || "-",
                beneBankName: item?.bene_bank_name || "-",
                ifscCode: item?.beneficiary_bank_ifsc || "-",
                paymentMode: item?.transaction_type || "-",
                businessId: item?.business_id || "-",
                webTop: item?.webtop_id || "-",
                caseStatus: item?.case_status || "-",
                cas_no: item?.cas_no || "-",
              };
              return newItem;
            });
          } else {
            setListdata([]);
            toast.error(res.data.message || errorMessages.SOMETHING_WRONG, {
              toastId: toastIds?.DataError,
            });
          }
          if (result?.length > 0) {
            setListdata([...result]);
            setCount(res?.data?.response?.pages * 10);
          }
        } else {
          setListdata([]);
          if (!showErr) {
            toast.error(res?.data?.message || errorMessages.SOMETHING_WRONG, {
              toastId: toastIds?.DataError,
            });
          }
        }
      }
    } catch (error) {
      toast.error(error.message || errorMessages.SOMETHING_WRONG, {
        toastId: toastIds?.DataError,
      });
      setListdata([]);
    } finally {
      setTableLoading(false);
    }
  };
  

  const changePage = (value) => {
    setPageNumber(value);
  };

  const onSwitchChange = async (isToggle, bank) => {
    try {
      let userData = LoginState?.decodedData;
      const apiBody = {
        user_name: userData?.user_name || "",
        user_code: userData?.user_code,
        isToggleOn: isToggle.toString(),
        bank_name: bank
      };
      const res = await apiCall.post(apiConstants?.AUTO_PAYMENT_TOGGLE, apiBody);
      if (res.data.status === 200 || res.data.status === 404) {
        toast.success(`Auto disbursement ${isToggle ? 'on' : 'off'}`);
      } else {
        toast.error(res?.data?.message, { toastId: toastIds?.FileUploaderr });
      }
    } catch (err) {
      console.log("err", err);
    }
  };
  
  const onPageLoadStatus = () => {
    apiCall
      .post(apiConstants?.regularPaymentToggleApiStatus)
      .then((res) => {
        if (res.data.status === 200) {
          if (res.data.response == 1) {
            setRegularICICISwitch(true);
          }
        } else {
          toast.error(res?.data?.message, {
            toastId: toastIds?.FileUploaderr,
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const getAutoDisbursementProductCode = () => {
    setLoading(true);
    let paramObj = {
      tablename: tableNamePaymentConstants?.AutoDisbursementProductCode,
      attributes: [attributePaymentConstants?.AutoDisbursementProductCode],
    };
    apiCall
      .post(apiConstants?.findData, paramObj)
      .then(async (res) => {
        let result = res?.data?.response;
        result = result.map((item) => {
          let newItem = {
            value: item.product_codes,
            label: item.product_codes,
          };
          return newItem;
        });
        setAutoDisbursementProductCode(result);
      })
      .catch((er) => {
        console.log("errr =>>>>", er);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getSwitchBankValues();
    onPageLoadStatus();
  }, []);

  useEffect(() => {
    listApi(pageNumber);
  }, [pageNumber]);

  const setView = () => {
    setTimeout(() => {
      listApi();
    }, 400);
  };

  return (
    <>
      <div className="mt-4 mx-3 pt-1">
        <div className="d-flex flex-wrap gap-1 pb-3 align-items-center">
          <label className="common-header cursor-pointer">
            Auto Regular Payment
          </label>
          <div className="d-flex flex-grow-1 justify-content-end">
            <div className="border rounded p-1 d-flex align-items-center">
              <div className="col-md-6 border-right p-1 custom-border">
                <div className="ml-2 mr-3">HDFC</div>
              </div>
              <div className="col-sm-2">
                <Form className="ml-5 mt-1">
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    label=""
                    size="lg"
                    className="custom-switch"
                    checked={regularHDFCSwitch}
                    onChange={() => setShowHDFCModal(true)}
                  />
                </Form>
              </div>
            </div>
            <div className="ml-2 border rounded p-1 d-flex align-items-center">
              <div className="col-md-6 border-right p-1 custom-border">
                <div className="ml-2 mr-3">ICICI</div>
              </div>
              <div className="col-sm-2">
                <Form className="ml-5 mt-1">
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    label=""
                    size="lg"
                    className="custom-switch"
                    checked={regularICICISwitch}
                    onChange={() => setShowICICIModal(true)}
                  />
                </Form>
              </div>
            </div>
            <div className="ml-2 border rounded p-1 d-flex align-items-center">
              <div className="col-md-6 border-right p-1 custom-border">
                <div className="ml-2 mr-3">SBI</div>
              </div>
              <div className="col-sm-2">
                <Form className="ml-5 mt-1">
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    label=""
                    size="lg"
                    className="custom-switch"
                    checked={regularSBISwitch}
                    onChange={() => setShowSBIModal(true)}
                  />
                </Form>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row regular-payment-wrapper-row mb-3">
            <div className="col-12 col-sm-12 col-md-12 col-lg-3 p-0">
              <Payment setView={() => setView()} isRegularAutoPayment />
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-9 p-0">
              <div className="RegularPayment_max-widthTable">
                {tableLoading || updateLoading ? (
                  <div className="text-center spinnerDiv">
                    <SystemXLoader />
                    <p>
                      <b>Loading...</b>
                    </p>
                  </div>
                ) : (
                  <Table />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {(showICICIModal || showHDFCModal || showSBIModal) && (
        <Modal
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showICICIModal ? showICICIModal : showHDFCModal ? showHDFCModal : showSBIModal ? showSBIModal : ''}
          onHide={handleClose}
        >
          <Modal.Header className="text-center " closeButton>
            <Modal.Title>Auto Payment</Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center ">
            <h4>{`Are you sure you want to turn this toggle button on for ${showICICIModal ? "ICICI": showHDFCModal ? "HDFC" : showSBIModal ? 'SBI' : ''} ?`}</h4>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => {
                if (showICICIModal) {
                  setRegularICICISwitch(!regularICICISwitch);
                  onSwitchChange(!regularICICISwitch, "ICICI BANK");
                }
                else if (showHDFCModal) {
                  setRegularHDFCSwitch(!regularHDFCSwitch);
                  onSwitchChange(!regularHDFCSwitch, "HDFC DIGI CFAB");
                }
                else if (showSBIModal) {
                  setRegularSBISwitch(!regularSBISwitch);
                  onSwitchChange(!regularSBISwitch, "SBI Retail CFAB Disb Account"); 
                }

                handleClose();
              }}
              className="mr-4 modalBtnCstm"
            >
              Yes
            </Button>
            <Button variant="secondary" onClick={handleClose}>
              No
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default memo(AutoRegularPayment);
