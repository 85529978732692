import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Button, InputGroup, Form as BootstrapForm } from "react-bootstrap";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// Dynamic Form Component
const FormFields = ({ fields, values, setFieldValue, handleChange }) => {
  return fields.map((field, index) => {
    switch (field.type) {
      case "datepicker":
        return (
          <div key={index}>
            <InputGroup style={{ maxWidth: "200px" }}>
              <DatePicker
                selected={values[field.name]}
                onChange={(date) => setFieldValue(field.name, date)}
                placeholderText={field.placeholder}
                dateFormat="dd/MM/yyyy"
                className="form-control"
                maxDate={new Date()}
                style={{ fontSize: "12px", color: "#2A3A59" }}
              />
            </InputGroup>
            <div className="text-danger" style={{ fontSize: "10px" }}>
              <ErrorMessage name={field.name} />
            </div>
          </div>
        );

      case "dropdown":
        return (
          <div key={index}>
            <Field
              as={BootstrapForm.Select}
              name={field.name}
              value={values[field.name]}
              onChange={handleChange}
              style={{ fontSize: "16px", color: "#2A3A59", width: "120px" }}
            >
              {field.options.map((option, i) => (
                <option key={i} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Field>
            <div className="text-danger" style={{ fontSize: "10px" }}>
              <ErrorMessage name={field.name} />
            </div>
          </div>
        );

      default:
        return null;
    }
  });
};

const DynamicFilterForm = ({ formConfig, onSubmit, loading }) => {
  const initialValues = formConfig.reduce((acc, field) => {
    acc[field.name] = field.initialValue || "";
    return acc;
  }, {});

  const validationSchema = Yup.object(
    formConfig.reduce((acc, field) => {
      acc[field.name] = field.validation;
      return acc;
    }, {})
  );

  const handleSubmit = (values) => {
    onSubmit(values);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue, handleChange }) => (
        <Form>
          <div
            className="d-flex align-items-center justify-content-between"
            style={{ backgroundColor: "#F6F7F9" }}
          >
            {/* Dynamic Form Fields */}
            <div className="d-flex align-items-center gap-2">
              <FormFields
                fields={formConfig}
                values={values}
                setFieldValue={setFieldValue}
                handleChange={handleChange}
              />

              {/* Preview Button */}
              <Button
                disabled={loading}
                type="submit"
                variant="warning"
                style={{
                  backgroundColor: "#FFDA47",
                  fontSize: "14px",
                  padding: "10px 24px",
                  width: "120px",
                  fontWeight: "500",
                  color: "#2A3A59",
                  border: "none",
                }}
              >
                Preview
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default DynamicFilterForm;
