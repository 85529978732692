export const YupMessages = {
  InvalidAccountNumber: "Account number must be a valid number",
  InvalidAccountHolderName: "Account holder should be valid Name",
  AccountHolderRequired: "Account holder is required",
  AccountNumberRequired: "Account Number is required",
  AccountTypeRequired: "Account type is required",
  MICRCodeIsRequired: "MICR code is required",
  IFSCCodeIsRequired: "IFSC code is required",
  ContributionRequired: "Contribution is required",
  IFSCCodeValid: "Please Enter Valid IFSC Code",
  ContributionInDigits: "Contribution should be between 1 to 100",
  EnterCycleDateInDigits: "Please Enter cycle Date in digits",
  InvalidCategoryCode: "Invalid Category Code",
  CategoryCodeRequired: "Category code is required",
  InvalidCategoryCode: "Invalid Category Code",
  CycleDateRequired: "Cycle date is required",
  MandateDateRequired: "Mandate date is required",
  MandateStartDateRequired: "Mandate Start date is required",
  MandateEndDateRequired: "Mandate End date is required",
  PleaseEnterAmountInDigits: "Please Enter Amount in digits",
  InvalidAmount: "Please enter valid amount",
  InvalidIFSCCode: "Please enter valid ifsc code",
  InvalidContribution: "Please enter valid contribution",
  InvalidCycleDateValue: "Please enter valid cycle date",
  BankNameIsRequired: "Bank name is required",
  BranchCodeIsRequired: "Branch code is required",
  CityIsRequired: "City is required",
  WebTopIdCIFNoLeaseIdRequired: "Web Top Id/CIF No/Lease Id is required",
  LoanNoRequired: "Loan no is required",
  LOSIdApplicationIdIsRequired: "LOS Id/Application id is required",
  CustomerNameIsRequired: "Customer name is required",
  PleaseRnterValidMobileNumber: "Please enter valid Mobile Number",
  MobileNumberRequired: "Mobile number is required",
  STDCodeIsRequired: "STD code is required",
  PleaseEnterValidEmail: "Please enter valid Email Address",
  PersonalEmailRequired: "Personal Email is required",

  BoardLineNumberRequired: "Board line number is required",
  OfficeEmailRequired: "Office email is required",
  PleaseEnterValidOfficeEmail: "Please enter valid Office Email",
  PersonalEmailIsRequired: "Personal email is required",
  PleaseEnterValidPersonalEmail: "Please enter valid Personal Email",
  AccountCodeRequired: "Account code is required",
  NameRequired: "Name is required",
  F1AccountCodeRequired: "F1 account code is required",
  OpenMandateRequired: "Open Mandate is required",
  isActiveRequired: "isActive is required",
  CompanyCodeRequired: "Company code is required",
  SourceSystemNameRequired: "Source system name is required",
  ProductCodeRequired: "Product code is required",
  VendorRequired: "Vendor Type is required",

  ReversalStatusRequired: "Reversal Status is required",
  TransactionStatusRequired: "Transaction Status is required",
  ReasonDescriptionRequired: "Reason Description  is required",
  techRequired: "Tech Notation is required",
  ClearedBounceFlagRequired: "Cleared/Bounce Flag  is required",
  SchemeCodeRequired: "Scheme code is required",
  SchemeNameRequired: "Scheme name is required",
  SchemeIdRequired: "Scheme Id  is required",
  AutoAPIRequired: "Auto API  is required",
  PennyDropRequired: "Penny drop  is required",
  HighPriorityRequired: "High Priority  is required",
  SourceSystemRequired: "Source system is required",
  SystemRequired: "System is required",
  UserCodeRequired: "User code is required",
  UserNameRequired: "User name is required",
  PasswordRequired: "Password is required",
  EmailIdIsRequired: "Email id is required",
  UserTypeRequired: "User type is required",
  TCLBranchRequired: "TCL branch is required",
  TCLBranchLocationRequired: "TCL branch location is required",
  StoreCodeRequired: "Store code is required",
  VendorRequired: "Vendor is required",
  StoreLocationRequired: "Store location is required",
  ProductRequired: "Product is required",
  isBlockRequired: "isBlock is required",
  isValid: "is-valid",
  isNumber: "is-number",
  ShouldNotAcceptZero: "not-only-zero",

  IsTenDigits: "is-ten-digits",
  InvalidCustomerName: "Customer Name should be valid Name",
  InvalidEmail: "Please  enter a valid email address",
  InvalidSTDCode: "Please input valid STD Code",
  MobileNumberMustHave10Digits: "Mobile number must have exactly 10 digits",
  InvalidBoadLineNo: "Please input valid BoardLine Number",
  MandateMaximumAmountRequired: "Mandate maximum amount is required",
  SelectMandateType: "Please select Mandate Type",
  SelectCompanyCode: "Please select Company Code",
  SelectProductType: "Please select Product Type",
  SelectSourceSystem: "Please select Source System",
  SelectPurposeType: "Please select Purpose Type",
  EnterValidLoanNumber: "Please input Loan Number",
  EnterLoanNUmber: "Please input Loan Number",
  EnterWebTop: "Please input valid Web Top",
  EnterLosId: "Please input valid Los ID",
  SelectFrequencyType: "Please select Frequency Type",
  CompanyNameRequired: "Company name is required",
  EMandateAmountRequired: "E-Mandate Amount is required",
  BaNCSFrequencyCodeRequired: "BaNCS Frequency Code is required",
  FrequencyRequired: "Frequency is required",
  MandateTypeRequired: "Mandate Type is required",
  BankCodeRequired: "Bank code is required",
  TclSponsorBankNameRequired: "Tcl Sponsor Bank Name is required",
  SponsorBankRequired: " Sponsor bank is required",
  F1PicklistNameRequired: "F1 Picklist name is required",
  GLCodeRequired: "GL Code is required",
  BancsMirrorRequired: "Bancs Mirror is required",
  CustomerTypeRequired: "Customer Type is required",
  BusinessIDRequired: "Business ID is required",
  PaymentModeRequired: "Payment mode is required",
  paymentModeCodeRequired: "payment mode code is required",
  ReceiptTypeNameRequired: "Receipt Type name is required",
  ReceiptTypeCodeRequired: "Receipt Type code is required",
  ClientCodeRequired: "Client Code is required",
  BankGlCodeRequired: "Bank Gl code is required",
  BankAccountNoRequired: "Bank Account No is required",
  PaymentMaxCapRequired: "Payment Max Cap is required",
  PaymentTypeModeRequired: "Payment Type Mode is required",
  SubProductRequired: "Sub product is required",
  UserIDRequired: "User ID is required",
  SelectRoleRequired: "Select Role is required",
  AssignRoleRequired: "Assign Role is required",
  userRequired: "user is required",
  PurposeRequired: "Purpose is required",
  IsEmailRequired: "Is Email is required",
  IsSMSRequired: "Is SMS is required",
  ValidityDateRequired: "Validity Date is required",
  KeyLengthRequired: "Key Length is required",
  RoleRequired: "Role is required",
  ManualMandateRequired: "Manual Mandate is required",
  PresentationTypeRequired: "Presentation Type is required",
  FromDateRequired: "From Date Is required",
  ToDateRequired: "To Date is required",
  SettlementDateRequired: "Settlement Date is required",
  AccountHolderNameRequired: "Please Enter Account Holder Name",
  DataRequired: "Data is required",
  CodeRequired: "Code is required",
  invalidProductType: "Please enter valid product type",
  invalidCompanyCode: "Please enter valid company code",
  invalidWebTopId: "Please enter valid web top id",
  invalidLoanNumber: "Please enter valid loan number",
  invalidApplicationId: "Please enter valid application id",
  invalidCustomerName: "Please enter valid customer name",
  invalidAccountHolderName: "Please enter valid account holder name",
  invalidAccountNumber: "Please enter valid account number",
  invalidMicrCode: "Please enter valid micr code",
  invalidIfscCode: "Please enter valid ifsc code",
  invalidBankName: "Please enter valid bank name",
  invalidBranchCode: "Please enter valid branch code",
  invalidCity: "Please enter valid city",
  ProductTypeRequired: "Product Type is required",
  VPARequired: "Please Enter valid VPA",
  BANK_NAME_IS_REQUIRED :"Bank Name is required",
  LOT_NUMBER:"Lot No is required",
  CHEQUE_FROM:"Cheque From is required",
  CHEQUE_TILL:"Cheque Till is required",  
  REMARK:"Remark is required",  
  
};

export const WhereQuery = {
  isActive: "1",
};

export const loginDropdownOptions = [
  { key: "Select an option", value: "" },
  { key: "External", value: "OTH" },
  { key: "Internal", value: "TCL" },
];
export const dummyList = [
  {
    batch_id: "757",
    upload_date_time: "12/1/2023, 2:06:03 am",
    user_name: "Ashwin Ujjainkar",
    upload_status: "Success",
    total_count: "27499",
    succuss_count: "27495",
    rejected_count: "4",
    pending_count: "0",
    file_name: "NACH_DTL_INR_DEMO1.csv",
  },
  {
    batch_id: "757",
    upload_date_time: "12/1/2023, 2:06:03 am",
    user_name: "Ashwin Ujjainkar",
    upload_status: "Success",
    total_count: "27499",
    succuss_count: "27495",
    rejected_count: "4",
    pending_count: "0",
    file_name: "NACH_DTL_INR_DEMO1.csv",
  },
  {
    batch_id: "757",
    upload_date_time: "12/1/2023, 2:06:03 am",
    user_name: "Ashwin Ujjainkar",
    upload_status: "Success",
    total_count: "27499",
    succuss_count: "27495",
    rejected_count: "4",
    pending_count: "0",
    file_name: "NACH_DTL_INR_DEMO1.csv",
  },
  {
    batch_id: "757",
    upload_date_time: "12/1/2023, 2:06:03 am",
    user_name: "Ashwin Ujjainkar",
    upload_status: "Success",
    total_count: "27499",
    succuss_count: "27495",
    rejected_count: "4",
    pending_count: "0",
    file_name: "NACH_DTL_INR_DEMO1.csv",
  },
  {
    batch_id: "757",
    upload_date_time: "12/1/2023, 2:06:03 am",
    user_name: "Ashwin Ujjainkar",
    upload_status: "Success",
    total_count: "27499",
    succuss_count: "27495",
    rejected_count: "4",
    pending_count: "0",
    file_name: "NACH_DTL_INR_DEMO1.csv",
  },
  {
    batch_id: "757",
    upload_date_time: "12/1/2023, 2:06:03 am",
    user_name: "Ashwin Ujjainkar",
    upload_status: "Success",
    total_count: "27499",
    succuss_count: "27495",
    rejected_count: "4",
    pending_count: "0",
    file_name: "NACH_DTL_INR_DEMO1.csv",
  },
  {
    batch_id: "757",
    upload_date_time: "12/1/2023, 2:06:03 am",
    user_name: "Ashwin Ujjainkar",
    upload_status: "Success",
    total_count: "27499",
    succuss_count: "27495",
    rejected_count: "4",
    pending_count: "0",
    file_name: "NACH_DTL_INR_DEMO1.csv",
  },
  {
    batch_id: "757",
    upload_date_time: "12/1/2023, 2:06:03 am",
    user_name: "Ashwin Ujjainkar",
    upload_status: "Success",
    total_count: "27499",
    succuss_count: "27495",
    rejected_count: "4",
    pending_count: "0",
    file_name: "NACH_DTL_INR_DEMO1.csv",
  },
  {
    batch_id: "757",
    upload_date_time: "12/1/2023, 2:06:03 am",
    user_name: "Ashwin Ujjainkar",
    upload_status: "Success",
    total_count: "27499",
    succuss_count: "27495",
    rejected_count: "4",
    pending_count: "0",
    file_name: "NACH_DTL_INR_DEMO1.csv",
  },
  {
    batch_id: "757",
    upload_date_time: "12/1/2023, 2:06:03 am",
    user_name: "Ashwin Ujjainkar",
    upload_status: "Success",
    total_count: "27499",
    succuss_count: "27495",
    rejected_count: "4",
    pending_count: "0",
    file_name: "NACH_DTL_INR_DEMO1.csv",
  },
  {
    batch_id: "757",
    upload_date_time: "12/1/2023, 2:06:03 am",
    user_name: "Ashwin Ujjainkar",
    upload_status: "Success",
    total_count: "27499",
    succuss_count: "27495",
    rejected_count: "4",
    pending_count: "0",
    file_name: "NACH_DTL_INR_DEMO1.csv",
  },
  {
    batch_id: "757",
    upload_date_time: "12/1/2023, 2:06:03 am",
    user_name: "Ashwin Ujjainkar",
    upload_status: "Success",
    total_count: "27499",
    succuss_count: "27495",
    rejected_count: "4",
    pending_count: "0",
    file_name: "NACH_DTL_INR_DEMO1.csv",
  },
  {
    batch_id: "757",
    upload_date_time: "12/1/2023, 2:06:03 am",
    user_name: "Ashwin Ujjainkar",
    upload_status: "Success",
    total_count: "27499",
    succuss_count: "27495",
    rejected_count: "4",
    pending_count: "0",
    file_name: "NACH_DTL_INR_DEMO1.csv",
  },
];
export const paymentDropdownValues = "paymentDropdownValues";
export const paytmPaymentDropdownValues = "paytmPaymentDropdownValues";
export const sampleFileKey = "sample_files/ProvisionSampleFile.xlsx";
export const caseDetailsSampleFileKey =
  "sampleFiles/failed_manual_marking_sample_format.xlsx";
export const exceptionSampleFileKey =
  "sampleFiles/regular_manual_marking_sample_format.xlsx";
export const accountType = [
  {
    value: "SavingAccount",
    key: "SavingAccount",
  },
  {
    value: "CurrentAccount",
    key: "CurrentAccount",
  },
];

export const monthOptions = [
  {
    value: "1 Month",
    label: "1 Month",
  },
  { value: "3 Month", label: "3 Month" },
  { value: "6 Month", label: "6 Month" },
];

export const toolTipMessages = {
  downloadMandateSampleFile:
    "Please download the sample file format and fill the mandate registration details",
  mandateStatus:
    "please complete the registration process to see the mandate status",
};

export const reportType = [
  {
    value: "ENACH Adoption Reports",
    label: "ENACH Adoption Reports",
  },
  {
    value: "ENACH Adoption Daily Reports",
    label: "ENACH Adoption Daily Reports",
  },
  {
    value: "SWAP ENACH Reports",
    label: "SWAP ENACH Reports",
  },
  {
    value: "ENACH Not Banked Customer Reports",
    label: "ENACH Not Banked Customer Reports",
  },
  {
    value: "ENACH Feedback Reports",
    label: "ENACH Feedback Reports",
  },
  {
    value: "Enach UPI Reports",
    label: "Enach UPI Reports",
  },
];
export const commonVariables = {
  INPROGRESS: "In Progress",
  Regular: "regular",
  DefaultTime:'00:00:00',
  PENDING_FOR_APPROVAL: 'pending for approval',
  STATUS: "Status",
  FileDownloadSuccess: "File downloaded successfully",
  Duplicate: "duplicate",
  Exception: "exception",
  Hold: "hold",
  Reject: "reject",
  OpportunityID: "Opportunity ID",
  TransactionID: "Transaction ID",
  Failed: "Failed",
  Savings: "Savings",
  Current: "Current",
  INDUSIND: "INDUSIND",
  Success: "Success",
  Pending: "Pending",
  DOWNLOAD_READY: "Download Ready",
  WebTopNo: "Web Top No",
  fsd: "FSD",
  dsd: "DSD",
  ifsc: "ifsc",
  bankName: "bankName",
  branchCode: "branchCode",
  city: "city",
  fixed: "fixed",
  Download: "Download",
  hdfc: "HDFC",
  DOWNLOAD_IS_BEING_READY: "Download is being generated....",
  DEMERGE: "Demerge",
  BACK_TO_DEMERGE_STATUS: "Back to De-merge Status",
  SOURCE_SYSTEM_DOWNLOAD: "Source System Download",
  DEMERGE_SUMMARY: "Demerge Summary",
  MERGE: "Merge",
  YES: "YES",
  NO: "NO",
  F1_Manual_Upload_File: "F1 Manual Upload File",
  FINNONE: "Finnone",
  BANCS_MANUAL_UPLOAD_FILE: "Bancs Manual Upload File",
  OTHER_SOURCE_SYSTEM: "OTHER_SOURCE_SYSTEM",
  OTHER_MANUAL_Upload_File: "Other Manual Upload File",
  FINNONE_MANUAL_REVERSAL: "FINNONE_Manual_Reversal",
  BANCS_MANUAL_REVERSAL: "BANCS_Manual_Reversal",
  DOWNLOAD_ID: "Download ID",
  BATCH_ID: "Batch ID",
  SOURCE_SYSTEM: "Source System",
  DOWNLOAD_STATUS: "Download Status",
  ACTION: "Action",
  EXPORT: "Export",
  DOWNLOAD: "Download",
  SYSTEM_COUNT_DATA: "System Count Data",
  UPLOAD: "Upload",
  SEARCH: "Search",
  PRESENTATION: "Presentation",
  DATE_TIME: "Date & Time",
  USER_NAME: "User Name",
  FILE_NAME: "File Name",
  SavingAccount: "SavingAccount",
  Micr: "Micr",

  FILE_UPLOAD_STATUS: "File Upload Status",
  TOTAL_RECORDS: "Total Records",
  SUCCESSFUL: "Successful",
  REJECTED: "Rejected",
  PENDING: "Pending",
  REPRESENTATION: "Representation",
  NO: "No",
  VALID: "Valid",
  INVALID: "Invalid",
  APPROVE: "Approve",
  REJECT: "Reject",
  SAP: "SAP",
  BANCS: "BANCS",
  MANUAL: "MANUAL",
  SAPECC6: "SAPECC6",
  CANCEL: "Cancel",
  REPRESENTATION_FILE_UPLOAD: "Representation File Upload",
  PRESENTATION_FILE_UPLOAD: "Presentation File Upload",
  SUBMIT: "submit",
  MANDATE_REG_REV_HDFC: "Mandate Registration Reversal SFTP HDFC",
  MANDATE_REG_REV_INDUS: "Mandate Registration Reversal SFTP Indusland",
  SRNO: "Sr. No.",
  BANK: "Bank",
  AD: "Ad",
  COMPANY_CODE: "Company Code",
  SFTP_FOLEDER: "SFTP Folder",
  FILE_REC_DATE: "File Recieved date",
  SFTP_PUSH: "SFTP Push",
  MANDATE: "Mandate",
  BILL_UPLOAD_STATUS: "Bill Upload Status",
  BILL_UPLOAD: "Bill Upload",
  NACH: "Nach",
  PROVISION_UPLOAD: "RPT Provision Upload",
  SFTP_MERGE_STATUS: "SFTP Merge Status",
  SFTP_MERGE_LOG: "SFTP Merge Log",
  COMPANY: "Company",
  PRODUCT_CODE: "Product Code",
  USER: "User",
  VIEW_RECORD: "View Records",
  PAN_NUMBER: "Pan Number",
  DATE_OF_BIRTH: "Date of Birth",
  CREATED_AT: "Create At",
  UPDATED_AT: "Updated At",
  LOAN_NO: "Loan No",
  CUSTOMER_NAME: "Customer Name",
  AMOUNT: "Amount",
  MANDATE_DATE: "Mandate Date",
  SETTLEMENT_DATE: "Settlement Date",
  NAME: "Name",
  E_MANDATE_AMOUNT: "E-Mandate Amount",
  IS_ACTIVE: "Is Active",
  MANDATE_TYPE: "Mandate Type",
  FREQUENCY: "Frequency",
  BANCS_FREQUENCY_CODE: "Bancs Frequency Code",
  SYSTEM_NAME: "System",
  SOURCE_SYSTEM_NAME: "Source System Name",
  VENDOR_TYPE: "Vender Type",
  ACCOUNT_CODE: "Account Code",
  F1_ACCOUNT_CODE: "F1 Account Code",
  OPEN_MANDATE_ACCOUNT: "Open Mandate Account Type",
  SPONSOR_BANK: "Sponsor Bank",
  ASSIGN_SPONSOR_UTILITY: "Assign Sponsor Utility",
  PAYMENT_MODE_CODE: "Code",
  CLIENT_CODE: "Client Code",
  BANK_NAME: "Bank Name",
  BANK_GL_CODE: "Bank GL Code",
  ACCOUNT_NUMBER: "Account No",
  PAYMENT_MAX_CAP: "Payment Max Cap",
  PAYMENT_TYPE_MODE: "Payment Type & Mode",
  BUSINESS_ID: "Business ID",
  CUSTOMER_TYPE: "Customer Type",
  CODE: "Code",
  ASSIGN_LESSEE_NAME: "Assign Lessee Name",
  VENDOR: "Vendor",
  REVERSAL_STATUS: "Reversal Status",
  TRASACTION_STATUS: "Transaction Status",
  RESON_DESCRIPTION: "Reason Description",
  SCHEME_CODE: "Scheme Code",
  SCHEME_NAME: "Scheme Name",
  SCHEME_ID: "Scheme ID",
  FILE_BASED_AUTO_API: "File Based/ Auto API",
  PENNYDROP_FLAG: "PennyDrop Flag",
  HIGH_PRIORITY: "High Priority",
  BANCS_BANK_CODE: "Bancs Bank Code",
  BANK_CODE: "Bank Code",
  USER_TYPE: "User Type",
  ISBLOCK: "Is Block",
  CHANGE_PASSWORD: "Change Password",
  ASSIGN_ROLE: "Assign Role",
  USER_ROLE: "Role",
  USER_ID: "User ID",
  COMPANY_NAME: "Company Name",
  PURPOSE: "Purpose",
  EMAIL: "Email",
  VALID_DATE: "Valid Date",
  ENIN: "en-IN",
  ASIAKOL: "Asia/Kolkata",
  DEMERGE_SUCCESS_MESSAGE: "Records are Approved",
  DEMERGE_REJECT_MESSAGE: "Records are Rejected",
  FILE_IS_BEING_PROCESSED:
    "Your file is currently being processed. Kindly wait for completion.",
  DT: "DT",
  Account_Validation: 'Account_Validation',
  PROCESSING: "Processing",
  RPT_PROVISION: "RPT Provision Upload",
  MANDATE_REGISTRATION_DEMERGE: "Registration Demerge",
  PHYSICAL_DEMERGE_STATUS: "Physical De Merge Status",
  SCAN_DEMERGE_STATUS: "Scan De Merge Status",
  AUTOPAY:"AutoPay",
  AUTO_PAYMENT_BANK_MASTER:"Auto Payment Bank Master",
  PARTNER_NAME: "Partner Name",
  AUTH_MODE: "Authentication mode",
  FIVE_FIELD:'Five_Field',
  SEVEN_FIELD:'Seven_Field'
};

export const S3FILEPATH = {
  COMMON: {
    UPLOADS: "Uploads",
    DOWNLOADS: "Downloads",
    DOWNLOAD: "download",
    SAMPLEFILES: "sampleFiles",
    REPORTS: "Reports",
    ACCOUNT_VALIDATION_REPORTS: "acct-validation-report",
  },

  ENACH: {
    ENACH: "Enach",
    NOTBANKED: "NotBanked",
  },
  REPORTS: {
    REPORTS: 'Reports',
    PROVISION_REPORT: "ProvisionReport",
  },

  PAYMENT: {
    PAYMENT: "payment",
  },
  DT: {
    DT: 'DebitTransaction',
    DEMERGE: 'Demerge',
    RAW: 'raw',
    PRESENTATION: 'Presentation',
    RPT_BILL_UPLOAD: 'RptBillUpload',
    RPT_PROVISION_UPLOAD: 'RptProvisionUpload',
    RPT_OTU: "RptOtu",
    MANDATE_REGISTRATION_DEMERGE:'MandateRegistrationDemerge'
  }
};

export const componentRole = {
  EXPORT: "export",
  DOWNLOAD: "download",
  TRANSPARENT: "transparent",
  PRIMARY: "primary",
  TRANSPARENT_DOWNLOAD: "transparentdownload",
  APPROVE: "approve",
  REJECT: "reject",
  TRANSPARENT_CANCEL: "transparentcancel",
  CANCEL: "cancel",
};

export const dateFormats = {
  dmy: "DD/MM/YYYY",
  dmyupd: "DD-MM-YYYY",
  ymd: "YYYY-MM-DD",
  mdy: "MM/DD/YYYY",
  dmyHms: "DD-MM-YYYY HH:mm:ss",
  dateFormatInDMYWithTimeAndAm: "DD-MM-YYYY, h:mm:ss a",
  dateFormatInMDYWithTimeAndAm: "MM/DD/YYYY, h:mm:ss A",
  dateFormatInDMYWithTime: "YYYY-MM-DD HH:mm:ss",
};

export const tableID = {
  ID:'id',
  NO: "no",
  Hdfc: "hdfc",
  leg: "leg",
  FINNONE_MANUAL_REVERSAL: "FINNONE Manual Reversal (Cleared Cases)",
  BANCS_MANUAL_REVERSAL: "BANCS Manual Reversal (Cleared Cases)",
  DOWNLOAD_ID: "download_id",
  BATCH_ID: "batch_id",
  SOURCE_SYSTEM: "source_system",
  DOWNLOAD_STATUS: "download_status",
  ACTION: "action",
  UPLOAD_DATE_TIME: "upload_date_time",
  USER_NAME: "user_name",
  FILE_NAME: "file_name",
  UPLOAD_STATUS: "upload_status",
  TOTAL_COUNT: "total_count",
  SUCCESS_COUNT: "succuss_count",
  REJECTED_COUNT: "rejected_count",
  PENDING_COUNT: "pending_count",
  VALID: "valid",
  PRODUCT_CODE: "Product_Code",
  INVALID: "invalid",
  SAP: "sap",
  BANCS: "bancs",
  FINNONE: "finnone",
  SAPECC6: "sapecc6",
  MANUAL: "manual",
  SRNO: "srno",
  BANK: "bank",
  COMPANY_CODE: "companyCode",
  USER: "User",
  FILENAME: "fileName",
  SFTP_FOLEDER: "sftpFolder",
  FILE_REC_DATE: "fileRecievedDate",
  DOWNLOAD: "download",
  SFTP_PUSH: "sftp_push",
  NAME: "name",
  DATE_TIME: "date_time",
  RECORDS: "records",
  MANDATE: "mandate",
  OTHER_SOURCE_SYSTEM: "OTHER Source System(Cleared Cases)",
  COMPANY: "Company",
  SFTP_PUSH_FLAG: "sftp_push_flag",
  VIEW_RECORD: "view_record",
  LOAN_NO: "loan_no",
  CUSTOMER_NAME: "customer_name",
  MANDATE_MAX_AMT: "mandate_maximum_amount",
  MANDATE_START_DATE: "mandate_start_date",
  MANDATE_END_DATE: "mandate_end_date",
  E_MANDATE_AMOUNT: "e_mandate_amount",
  IS_ACTIVE: "isActive",
  MANDATE_TYPE: "mandate_type",
  FREQUENCY: "frequency",
  BANCS_FREQUENCY_CODE: "bancs_frequency_code",
  SYSTEM_NAME: "system_name",
  SOURCE_SYSTEM_NAME: "source_system_name",
  VENDOR_TYPE: "vendor",
  ACCOUNT_CODE: "account_code",
  F1_ACCOUNT_CODE: "f1_account_code",
  OPEN_MANDATE_ACCOUNT: "open_mandate_account_type",
  SPONSOR_BANK: "sponsorBank",
  ASSIGN_SPONSOR_UTILITY: "assign_sponsor_utility",
  PAYMENT_MODE_CODE: "payment_mode_code",
  CLIENT_CODE: "client_code",
  BANK_NAME: "bank_name",
  BANK_GL_CODE: "bank_gl_code",
  ACCOUNT_NUMBER: "account_no",
  PAYMENT_MAX_CAP: "payment_max_cap",
  PAYMENT_TYPE_MODE: "payment_type_mode",
  BUSINESS_ID: "business_id",
  CUSTOMER_TYPE: "customer_type",
  CODE: "code",
  ASSIGN_LESSEE_NAME: "assign_lessee_name",
  REVERSAL_STATUS: "reversal_status",
  TRASACTION_STATUS: "transaction_status",
  RESON_DESCRIPTION: "reson_description",
  SCHEME_CODE: "scheme_code",
  SCHEME_NAME: "scheme_name",
  SCHEME_ID: "scheme_id",
  FILE_BASED_AUTO_API: "file_based_auto_api",
  PENNYDROP_FLAG: "pennydrop_flag",
  HIGH_PRIORITY: "high_priority",
  BANCS_BANK_CODE: "bancs_bank_code",
  BANK_CODE: "bankCode",
  USER_TYPE: "user_type",
  ISBLOCK: "isBlock",
  CHANGE_PASSWORD: "change_password",
  ASSIGN_ROLE: "assign_role",
  USER_ROLE: "user_role",
  USER_ID: "user_id",
  COMPANY_NAME: "company_name",
  PURPOSE: "purpose",
  EMAIL: "email",
  VALID_DATE: "valid_date",
  PARTNER_NAME: "partner_name",
  AUTH_MODE: "auth_mode",
  IS_AUTH_MODE_ACTIVE: "active_status",
  USER_CODE: "user_code"
};
export const route = {
  DT_DEMERGE_STATUS: "/dtdemergestatus",
  DT_DEMERGE_SYSTEM: "/dtdemergesystemcount",
  DT_DEMERGE_FINNONE_DOWNLOAD: "/dtdemergefinnonedownload",
  DEBIT_TRANSACTION_SOURCE_MANUAL_DOWNLOAD: "/dtsourceManualDownload",
  SCAN_SFTP_MERGE_VIEW: "/reports/scanSftpMergeView",
  SCAN_MERGE_STATUS_VIEW: "/reports/scanMergeView",
  PHYSICAL_DEMERGE_STATUS:'/physicaldemergestatus',
  SCAN_DEMERGE_STATUS:'/scandemergestatus',
  MANDATE_REGISTRATION:'/mandateregistrationdemerge'
};
export const selectoptions = [
  { value: 1, label: "1 Month" },
  { value: 2, label: "2 Month" },
  { value: 3, label: "3 Month" },
  { value: 6, label: "6 Month" },
];
export const type = [
  { value: "regular", label: "Payment pending to release" },
  { value: "sent for payment", label: "payment release and sent to bank" },
  { value: "hold", label: "Hold marked cases" },
  { value: "reject", label: "Reject" },
  { value: "duplicate", label: "Duplicate Payment Data" },
  { value: "exception", label: "Exceptional Cases" },
  { value: "Newtotal", label: "New Total payment report"},
  { value: "total", label: "Total payment report" },
  { value: "Newtotal", label: "New Total payment report"}, 
  { value: "PaymentAdvice", label: "Payment Advice Report" },
  { value: 'smsHold', label:'Sms Report' },
];
export const selectdownload = [
  { value: "Five_Field", label: "Five Field" },
  { value: "Seven_Field", label: "Seven Field"},
  { value: "Download", label: "Banking File" },
];
export const options = [
  {
    value: "Opportunity ID",
    label: "Opportunity ID",
  },
  {
    value: "Web Top No",
    label: "Web Top No",
  },
  {
    value: "Los ID",
    label: "Los ID",
  },
  {
    value: "Transaction ID",
    label: "Transaction ID",
  },
];

export const DSD = "DSD";
export const bankWiseDate = [
  { bankName: "ALD", date: "10/04/2022" },
  { bankName: "BOB", date: "10/04/2022" },
  { bankName: "BOI", date: "10/04/2022" },
  { bankName: "BOM", date: "10/04/2022" },
  { bankName: "CBI", date: "10/04/2022" },
  { bankName: "CORP", date: "10/04/2022" },
  { bankName: "CUB", date: "10/04/2022" },
  { bankName: "DENA", date: "10/04/2022" },
  { bankName: "FBI", date: "10/04/2022" },
  { bankName: "HDFC", date: "10/04/2022" },
  { bankName: "HDFC2", date: "10/04/2022" },
  { bankName: "ICICI-PL", date: "10/04/2022" },
  { bankName: "IDBI", date: "10/04/2022" },
  { bankName: "INDIAN", date: "10/04/2022" },
  { bankName: "INDUSIND", date: "10/04/2022" },
  { bankName: "ING", date: "10/04/2022" },
  { bankName: "KOTAK", date: "10/04/2022" },
  { bankName: "OBC", date: "10/04/2022" },
  { bankName: "PNB", date: "10/04/2022" },
  { bankName: "SBM", date: "10/04/2022" },
  { bankName: "SYNDICATE", date: "10/04/2022" },
  { bankName: "TECHPROCESSSOLUTION", date: "10/04/2022" },
  { bankName: "TPSL", date: "10/04/2022" },
  { bankName: "UBI", date: "10/04/2022" },
  { bankName: "UTB", date: "10/04/2022" },
  { bankName: "VIJAYA", date: "10/04/2022" },
];
export const PresentationOptions = [
  {
    key: "Presentation",
  },
  {
    key: "Representation",
  },
];

export const PresentationOptionsNew = [
  {value: "Presentation", label: "Presentation" },
  {value: "Representation_ENACH", label: "Re-Presentation ENACH" },
  {value: "Representation_UPI", label: "Re-Presentation UPI Mandate" },
];

export const reportTypeOptions = [
  { value: "reg_pre_reports", label: "Reg Sent To Bank Report" },
  { value: "reg_post_reports", label: "Reg Bank Response Report" },
  { value: "tran_pre_reports", label: "DT Sent To Bank Report" },
  { value: "tran_post_reports", label: "DT Bank Response Report" },
  {
    value: "representation_pre_reports",
    label: "DT Re-presentation Sent To Bank",
  },
  {
    value: "representation_post_reports",
    label: "DT Re-presentation Response from Bank",
  },
  {
    value: "representation_bounce_cases",
    label: "DT Re-presentation Bounce Report",
  },
  {
    value: "pdn_report_status",
    label: "UPI PDN Report",
  },
  {
    value: "dt_report_status",
    label: "UPI Autopay Report"
 
  }
];

export const provisionReportType = [
  { value: "EMANDATE_AND_REVERSAL", label: "EMANDATE AND IO REVERSAL" },
  { value: "OPEN_MANDATE", label: "F1 OPEN MANDATE REVERSAL" },
  { value: "F1_HEALTH_REJECT", label: "F1 HEALTH CHECK REJECT REVERSAL" },
  { value: "tran_post_reports", label: "DT Bank Response Report" },
  {
    value: "EMANDATE_EXCEPTION",
    label: "EMANDATE EXCEPTION",
  },
  {
    value: "M_N_CASES_PROVISION",
    label: "E-Mandate /reversal file from Other Channels",
  },
  {
    value: "SWAP_PROVISION",
    label: "SWAP PROVISONAL CASES",
  },
  {
    value: "NOT_BANKED_PROVISIONAL_UPLOAD",
    label: "NOT BANKED PROVISION UPLOAD",
  },
];

export const repaymentModeOptions = [
  { value: "LEG", label: "Mandate - LEGACY" },
  { value: "1", label: "Mandate - NACH" },
  { value: "2", label: "Mandate - AD" },
  { value: "3", label: "Mandate - UPI" },

];

export const provisionModeOptions = [
  { value: "1", label: "Mandate - NACH" },
  { value: "2", label: "Mandate - AD" },
];

export const sourceSystemOptions = [
  { value: "FINNONE", label: "FINNONE" },
  { value: "BANCS", label: "BANCS" },
];

export const channels = [
  { key: "Please Select Channels", value: "" },
  { key: "Moratorium", value: "TKP_M" },
  { key: "Dashboard", value: "TKP_D" },
  { key: "Whatsapp", value: "WHATSAPP" },
  { key: "Moratorium Mobile", value: "TKP_MM" },
  { key: "Dashboard Mobile", value: "TKP_DM" },
  { key: "Restructuring", value: "JOCATA_R" },
  { key: "TKP_PRE_APPROVED", value: "TKP_PRE_APPROVED" },
  { key: "TKP_NON_PRE_APPROVED", value: "TKP_NON_PRE_APPROVED" },
  { key: "Restructuring", value: "Restructuring" },
];

export const Month = [
  {
    value: "1 Month",
    label: "1 Month",
  },
  {
    value: "3 Month",
    label: "3 Month",
  },
  {
    value: "6 Month",
    label: "6 Month",
  },
  {
    value: "Set Custom",
    label: "Set Custom",
  },
];

export const companyCodeOptions = [
  { key: "Please Select Company Code", value: "" },
  { key: "7000", value: "7000" },
  { key: "9000", value: "9000" },
];

export const mandateType = [
  { key: "Please Select Mandate Type", value: "" },
  { key: "ENACH", value: "ENACH" },
  { key: "NACH", value: "NACH" },
];

export const repaymentMode = [
  { key: "Please Select Repayment Mode", value: "" },
  { key: "Mandate-Nach", value: "1" },
  { key: "Mandate-AD", value: "0" },
];

export const systemCode = [
  { key: "Please Select System Code", value: "" },
  { key: "FINNONE", value: "FINNONE" },
  { key: "BANCS", value: "BANCS" },
];

export const AuthModes = {
  UPI: "UPI",
  DEBITCARD: "DebitCard",
  NETBANKING: "NetBanking",
};

export const EnachUPIPaymentModes = {
  UPI_ID: "UPI",
  UPI_APP: "UPI_INTENT",
  QR_CODE: "QR_CODE",
};

export const EnachStatusCode = {
  FAIL_STATUS_CODE: "0399",
  SUCCESS_STATUS_CODE: "0300",
  HOUSING_COMPANY_CODE: "9000",
  FINANCIAL_COMPANY_CODE: "7000",
  MANDATE_TYPE: "ENACH",
  VENDOR_TYPE: { NUPAY: "NUPAY", TPSL: "TPSL" , ICICI: "ICICI" },
  NUPAY_ESIGN_BANK_ID:
  ["666", "640", "347", "641", "635", "4", "642", "29", "648", "649", 
    "669", "284", "82", "670", "671", "673", "7", "247", "44", "10", 
    "651", "11", "24", "634", "69", "682", "683", "659", "1", "15"],
  VENDOR_TYPES: ["NUPAY" , "TPSL"],
  SFDC: "SFDC",
  SFDC_CS: "SFDC_CS",
  NOT_BANKED: "Not banked",
  NOT_BANKED_SOURCE_SYSTEM: "FINNONE",
  SWAP_CASE_TYPE: "SWAP",
  TCFSL_COMPANY_CODE: "7000",
  TCHFL_COMPANY_CODE: "9000",
  LOAN_INSTALLMENT: "Loan Installment",
  REGULAR_CASE_TYPE: "REGULAR",
  CUSTOMER_INITIATED_TRANSACTION: "Customer Initiated Transaction",
  NON_ENCODED_VALUES : 'Non-Encoded Values sent to Tech-Process',
  ENCODED_VALUES : 'MD5 Encoded Values sent to Tech-Process',
  CUSTOMER_INITIATED_TRANSACTION_NUPAY : 'Customer Initiated Transaction/NUPAY',
  MANDATE_STATUS_CODES: ["0300", "0399", "0392", "0398"],
  ENABLE_SOURCE_SYSTEMS: [
    "SW_MOBILEAPP",
    "WHATSAPP",
    "TKP_D",
    "SFDC_CS",
    "TKP_PRE_APPROVED",
    "TKP_NON_PRE_APPROVED",
  ],
  NUPAY_RECR_VALUE: "RCUR",
  NUPAY_CATEGORY_CODE: 7,
  TYPE: "manual",
};

export const S3Keys = {
  EnachUserLogs: "EnachLogs/UserLogs",
};

export const StatusCodeFlags = {
  TRUE: true,
  FALSE: false,
  TRUE_FLAG: "true",
  FALSE_FLAG: "false",
};

export const dropdownOptions = [
  { key: "Select an option", value: "" },
  { key: "Yes", value: 1 },
  { key: "No", value: 0 },
];

export const isBlockDropdown = [
  { key: "Select an option", value: "" },
  { key: "Yes", value: 1 },
  { key: "No", value: 0 },
];

export const termsAndConditions = {
  params: `I have understood that I am authorised to cancel/amend this
  mandate by
  appropriately
  communicating the cancellation/amendment
  request to the user entity/corporate or the bank where I have
  authorized
  the
  debit.
  I/We
  hereby declare that the above information is true and correct
  and that
  the
  mobile
  number
  listed above is registered in my/our name(s) and/or is the
  number that
  I/we
  use in
  the
  ordinary course. I/We hereby declare that, irrespective of
  my/our
  registration of
  the
  above mobile in the provider customer preference register, or in
  any
  similar
  register
  maintained under applicable laws, now or subsequent to the date
  hereof,
  I
  consent to
  the Bank communicating to me/us about the transactions carried
  out in
  my/our
  aforesaid
  account(s). where I have authorized the debit. I/We hereby
  declare that
  the
  above
  information
  is true and correct and that the mobile number listed above is
  registered in
  my/our
  name(s)
  and/or is the number that I/we use in the ordinary course. I/We
  hereby
  declare that,
  irrespective of my/our registration of the above mobile in the
  provider
  customer
  preference
  register, or in any similar register maintained under applicable
  laws,
  now
  or
  subsequent
  to the date hereof, I/we consent to the Bank communicating to
  me/us
  about
  the
  transactions
  carried out in my/our aforesaid account(s).`,
};

export const aadharConditions = {
  params : 
    `Please use this mode only if your Aadhaar number is linked to your bank account. We recommend checking the Aadhaar linkage status with your bank before initiating the mandate request.
    `
}

export const CaseDetailUrl = "/caseDetails";
export const autoDebit = "ad";
export const consolidateBank = "consolidate bank--consolidate bank";
export const rePresentation = "representation";
export const presentation = "presentation";
export const representaionBankDateError =
  "Can't perform Merging for type Presentation with sponsor code Consolidate Bank.";

export const FILE_FORMAT = {
  csv: "csv",
  XLS: "xls",
  XLSX: "xlsx",
};

export const QUERY_PARAM = {
  Single: 'single',
  Bulk: 'bulk',
  pennyDropDump: 'pennyDropDump',
  REPRESENTATION_BOUNCE_CASES: "representation_bounce_cases",
  REPRESENTATION_POST_REPORTS: "representation_post_reports",
  REG_PRE_REPORTS: "reg_pre_reports",
  REG_POST_REPORTS: "reg_post_reports",
  TRAN_PRE_REPORTS: "tran_pre_reports",
  TRAN_POST_REPORTS: "tran_post_reports",
  REPRESENTATION_PRE_REPORTS: "representation_pre_reports",
  DT_PDN_REPORT_STATUS: "pdn report status",
  DT_REPRESENTATION_REPORT_STATUS: "dt report status",
  REPRESENTATION_BOUNCE_REPORT1: "DT representation bounce report",
  SINGLE_VALIDATION_REPORT: 'Single account validation report',
  BULK_VALIDATION_REPORT: 'Bulk account validation report',
  PENNY_DROP_VALIDATION_REPORT: 'Penny drop dump report',
  DT_REPRESENTATION_RESPONSE_REPORT1: "DT re-presentation response from bank",
  REG_SENT_BANK_REPORT: "Reg sent to bank report",
  REG_BANK_RESPONSE_REPORT: "Reg bank response report",
  DT_SENT_BANK_REPORTS: "DT sent to bank report",
  DT_BANK_RESPONSE_REPORTS: "DT bank response report",
  DT_REPRESENTATION_BANK_REPORTS: "DT representation sent to bank report",
  PDN_REPORT_STATUS: "pdn_report_status",
  DT_REPORT_STATUS: "dt_report_status",
  PROCESSING: "Processing",
  REPRESENTATION_BOUNCE_REPORT1_FILE: "Representation_Bounce_Report",
  DT_REPRESENTATION_RESPONSE_REPORT1_FILE:
    "DT_re-presentation_response_from_bank",
  REG_SENT_BANK_REPORT_FILE: "Reg_Sent_To_Bank_Report",
  DT_SENT_BANK_REPORTS_FILE: "DT_Sent_To_Bank_Reports",
  DT_BANK_RESPONSE_REPORTS_FILE: "DT_Bank_Response_Report",
  DT_REPRESENTATION_BANK_REPORTS_FILE: "DT representation sent to bank report",
};

export const BANK_MAPPINGS = {
  ICICI_API: 'ICICI', 
  SBI_API: 'SBI'
};