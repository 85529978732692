/* eslint-disable eqeqeq */
import React, { Component, useEffect, useState } from "react";
import CustomTable from "../../../component/customtable/CustomTable";
import "../apiStatus/Status.scss";
import downloadIcon from "../../../Images/download.svg";
import apiCall from "../../../utils/apiFunction/apiCall";
import axios from "axios";
import moment from "moment";
import { toast } from "react-toastify";
import {
  apiConstants,
  errMessage,
  toastIds,
} from "../../../constants/Common/apiConstants";
import SystemXLoader from "../../../component/common/Loader/SystemXLoader";
import DynamicForm from "../../../component/common/DynamicForm/dynamicForm";
import {
  BANK_MAPPINGS,
  commonVariables,
  dateFormats,
} from "../../../constants/Common/commonConstants";
import { useDispatch, useSelector } from "react-redux";
import {
  setFromDateTime,
  setToDateTime,
} from "../../../redux/reducer/dateTimeReducer";
import { errorMessages } from "../../../constants/Common/errorConstants";
const Apistatusbatch = () => {
  const dateTime = useSelector((state) => state.dateTimeReducer);
  console.log(dateTime, "dateTime");
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [count, setCount] = useState(0);
  const [listdata, setListdata] = useState([]);
  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState([
    {
      type: "dateinput",
      value: "",
      label: "From Date",
      placeholder: "Select Date",
      required: true,
      width: 6,
    },
    {
      type: "dateinput",
      value: "",
      label: "To Date",
      placeholder: "Select Date",
      required: true,
      width: 6,
    },
  ]);

  const downloadbtn = (batchId,disableOption) => {
    return (
      <div>
        <button
          onClick={() => onDownload(batchId)}
          className="downloadbtn pt-1 pe-2 pe-2 d-flex align-items-center justify-content-center"
          disabled= {disableOption} 
        >
          <img src={downloadIcon} alt="download" />
          Download
        </button>
      </div>
    );
  };

  const setDateTime = (value) => {
    const ToTimeNotSelected =
        (value[0]?.["From Time"] && !value[1]?.["To Time"]) ||
        (!value[0]?.["From Time"] && value[1]?.["To Time"]);
    const ToDateGreaterThanFromDate =
      value[1]?.["To Date"] >= value[0]?.["From Date"];
    const SameDateTimeError = moment(value[1]?.["To Date"]).isSame(
      moment(value[0]?.["From Date"]),
      "date"
    )
      ? moment(value[1]?.["To Date"]).isSame(
          moment(value[0]?.["From Date"]),
          "date"
        ) && value[0]?.["From Time"] >= value[1]?.["To Time"]
      : false;

    if (!ToDateGreaterThanFromDate) {
      toast.error(errorMessages.toDateMustBeGreaterThanToDate, {
        toastId: toastIds?.DataError,
      });
    } else if (
      value[1]?.["To Time"] &&
      value[0]?.["From Time"] &&
      SameDateTimeError
    ) {
      toast.error(errorMessages.FromTimeShouldBeLessThanToTime, {
        toastId: toastIds?.DataError,
      });
    } else if (ToTimeNotSelected) {
      toast.error(errorMessages?.EnterValidTimeRange, {
        toastId: toastIds?.DataError,
      });
    } else {
      dispatch(setFromDateTime(value[0]?.["From Date"]));
      dispatch(setToDateTime(value[1]?.["To Date"]));
    }
  };

  const onDownload = (batchId) => {
    setLoading(true);
    apiCall
      .get(`${apiConstants.paymentApiStatusDownloadStatus}?batch_id=${batchId}`)
      .then(async (res) => {
        if (res?.status === 200) {
          setLoading(false);
          let result = res?.data;
          if (result) {
            if (typeof res?.data?.response == "string") {
              const link = document.createElement("a");
              link.href = res?.data?.response;
              link.click();
              toast.success(commonVariables?.FileDownloadSuccess, {
                toastId: toastIds?.FileDownloadSucess,
              });
            } else {
              toast.error(res?.message || errMessage?.somethingWentWrong, {
                toastId: toastIds?.DataError,
              });
            }
          }
        } else {
          setLoading(false);
          toast.error(res?.message || errMessage?.somethingWentWrong, {
            toastId: toastIds?.DataError,
          });
        }
      })
      .catch((er) => {
        setLoading(false);
        toast.error(er?.message || errMessage?.somethingWentWrong, {
          toastId: toastIds?.DataError,
        });
      });
  };

  const listApi = (value) => {
    setLoading(true);
    let temp = 0;
    if (value > 0) {
      temp = (value - 1) * 10;
    }

    let postData = {
      fromDate :moment(dateTime?.fromDateTime).format("YYYY-MM-DD"),
      toDate: moment(dateTime?.toDateTime).format("YYYY-MM-DD"),
      offset:temp
    }
    apiCall
      .post(apiConstants?.fetchStatusBatchId, postData)
      .then(async (res) => {
        if (res.status === 200) {
          let result = res?.data?.response?.data;
          if (result.length > 0) {
            result = result.map((item) => {
              console.log(item?.bankName, "--------bankname")
              let newItem = {
                b_id: item.batch_id,
                date: moment(item?.createdAt).format(dateFormats?.dmyHms),
                totalcase: item?.total_records && item?.total_records > 0 ? item?.total_records :"-",
                bankname: BANK_MAPPINGS[item?.bankName] || 'HDFC',
                user: item?.user,
                action: item?.total_records && item?.total_records > 0  ? downloadbtn(item?.batch_id,false) : downloadbtn(item?.batch_id,true) ,
              };
              return newItem;
            });
            setCount(res?.data?.response?.totalCount);
            if (result?.length > 0) {
              setListdata([...result]);
            }
          } else {
            // setCount(res?.data?.count);
            setCount([])
            setListdata([]);
            toast.error(res?.message || errMessage?.noDataFound, {
              toastId: toastIds?.SomethingWentWrong,
            });
          }
        } else {
          setListdata([]);
          toast.error(res?.message || errMessage?.somethingWentWrong, {
            toastId: toastIds?.SomethingWentWrong,
          });
        }
      })
      .catch((er) => {
        setListdata([]);
        toast.error(er?.message || errMessage?.somethingWentWrong, {
          toastId: toastIds?.SomethingWentWrong,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    listApi();
  }, [dateTime]);

  const changePage = (value) => {
    listApi(value);
    setPageNumber(value - 1);
  };

  return (
    <div>
      <div className="disbursementReport_inputContainer mx-4 p-4">
        <DynamicForm
          onSubmit={setDateTime}
          isDownload
          formValues={formValues}
        />
      </div>
      {loading && (
        <div className="loaderDiv">
          <SystemXLoader />
        </div>
      )}
      <div>
        <div className="ml-3">
          <div className="mt-4 pt-2">
            <p className="statusTxt text-nowrap">
              <b>API Status Batch</b>
            </p>
          </div>
          <CustomTable
            cols={[
              {
                id: "b_id",
                title: "Batch ID",
                classStyle: {},
              },
              {
                id: "date",
                title: "Date & Time",
                classStyle: {},
              },
              {
                id: "totalcase",
                title: "Total Cases",
                classStyle: {},
              },
              {
                id: "bankname",
                title: "Bank Name",
                classStyle: {},

              },
              {
                id: "user",
                title: "User",
                classStyle: {},
              },
              {
                id: "action",
                title: "Action",
                classStyle: {},
              },
            ]}
            data={listdata}
            count={count}
            setPagination={(page) => changePage(page)}
            activePage={pageNumber}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
};
export default Apistatusbatch;
