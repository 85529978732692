import React, { useEffect, useState } from "react";
import Button from "../../../component/common/Button/button";
import "./StandaloneFileUpload.scss";
import Dropzone from "react-dropzone";
import uploadIcon from "../../../Images/upload-icon.svg";
import file from "../../../Images/file.svg";
import deleteIcon from "../../../Images/delete.svg";
import apiCall from "../../../utils/apiFunction/apiCall";
import {
  apiConstants,
  enachApiConstants,
  errMessage,
  errorMsg,
  singleAccountVerificationAPIMessage,
  successMsg,
  toastIds,
} from "../../../constants/Common/apiConstants";
import { toast } from "react-toastify";
import uploadPresigned from "../../../utils/presignedUpload";
import { useSelector } from "react-redux";
import SystemXLoader from "../../../component/common/Loader/SystemXLoader";
import { commonVariables, sampleFileKey } from "../../../constants/Common/commonConstants";
import moment from "moment";
import { errorMessages } from "../../../constants/Common/errorConstants";
const StandaloneFileUpload = () => {
  // const [sponserBank, setSponsorBank] = useState([]);
  const [sponserbankvalue, setSponserBankvalue] = useState("");
  const [fileData, setFileData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fileKey, setFileKey] = useState("");
  const [downloadFlag, setDownloadFlag] = useState(false);

  const [uploadPercent, setUploadPercent] = useState(0);
  const onDrop = (file) => {
    // if ((file[0]?.type != ("text/csv"))"text/plain")) {
    //   toast.error("Please select csv / txt file only", { toastId: "selectCsv" });
    // } else 
    if (file.length > 0) {
      // check for duplicate file name in backend
      checkForDuplicateFile(file)
      // getPresigned(file);
    } else {
      return;
    }
  };

  let LoginState = useSelector((state) => state.login);

  const uploadLoder = (data) => {
    setUploadPercent(data);
  };

  const checkForDuplicateFile = (value) => {
    let paramsObj = {
      tablename: "standalone_file_log",
      order: [["batch_id", "desc"]],
      whereQuery: {
        file_name: value[0].name
      }
    }
    apiCall
    .post(apiConstants?.fetchAllData, paramsObj)
    .then(res => {
      console.log(res, res.data)
      if(res?.status === 200 ) {
        
        if(res?.data?.response.length !== 0) {
          toast.error(errMessage.duplicateFileName, {
            toastId: toastIds?.DataError
          })
        } else {
          
          getPresigned(value)
        }
      } else {
        toast.error(errMessage?.InternalServerError, {
          toastId: toastIds?.InternalServerError,
        })
      }
    })
    .catch(error => {
      console.log('er', error)
      toast.error(errMessage?.InternalServerError, {
        toastId: toastIds?.InternalServerError,
      })
    })
  }

  const getPresigned = (value) => {
    setLoading(true);
    apiCall
      .get(
        `${apiConstants?.presignedS3upload}?key=${moment().format('YYYY/MM/DD').toString()}/standalone/upload/recurringAA/${value[0].name}`
      )
      .then(async (res) => {
        if (res.status === 200) {
          let result = res?.data;
          if (result) {
            uploadPresigned(value[0], result, uploadLoder)
              .then((res) => {
                if (res.status == 200) {
                  setFileData(value);
                  setFileKey(
                    `${moment().format('YYYY/MM/DD').toString()}/standalone/upload/recurringAA/${value[0].name}`
                  );
                  toast.success(successMsg?.fileUploadedSuccessfully, {
                    toastId: toastIds?.FileUploadSuccess,
                  });
                }
              })
              .catch((e) => {
                setFileKey("");
                setFileData([]);
                toast.error(errMessage?.fileUploadFailed, {
                  toastId: toastIds?.FileUploaderr,
                });
              })
              .finally(() => {
                setLoading(false);
              });
          }
        }
      })
      .catch((er) => {
        setFileKey("");
        setFileData([]);
        setLoading(false);
        toast.error(errMessage?.fileUploadFailed, {
          toastId: toastIds?.FileUploaderr,
        });
      });
  };

  const clearData = () => {
    setFileData([]);
    setFileKey("");
    setSponserBankvalue("");
  };

  const onSubmit = () => {
    if (fileKey) {
      let userCode = LoginState?.decodedData?.user_code;
      let postData = {
        user_code: userCode || "",
      };
      setLoading(true);
      apiCall
        .post(`${enachApiConstants?.finboxFileUpload + fileKey}`, postData)
        .then((res) => {
          if (res?.data?.status === 200) {
            clearData();
            toast.success(singleAccountVerificationAPIMessage?.fileProcessStarted, {
              toastId: toastIds?.recordSentSuccessfully,
            });
          } else {
            toast.error(
              res?.data?.message || res?.data?.message || res?.error,
              {
                toastId: toastIds?.FileUploaderr,
              }
            );
          }
        })
        .catch((er) => {
          let resp = er?.message || er?.response?.data?.error || "";
          toast.error(resp, { toastId: toastIds?.FileUploaderr });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      toast.error(errMessage?.uploadFileAndSelectValue, {
        toastId: toastIds?.FileUploaderr,
      });
    }
  };

  return (
    <>
      {(loading || downloadFlag) && (
        <div className="loaderDiv">
          <SystemXLoader />
        </div>
      )}
      <div className="manage-reversal-container mt-4 pt-2 ">
        <label className="common-header mb-3">Standalone File Upload</label>
        <div className="shadow border border-1 rounded-2 mr-3" id="c">
          <div className="ml-3 mr-3">
            <div className="d-flex mt-3 flex-wrap justify-content-between h-25 align-items-center">
              <div>
                <p className="CaseDetails_DisbursmentCaseWrapper_uploadlabel">
                  <b>Upload File</b>
                </p>
              </div>
              {/* <div>
                <button
                  type="button"
                  onClick={() => downloadSampleFile()}
                  className="paymentReversaldownloadIcon mb-2"
                >
                  <img
                    src={downloadIcon}
                    alt="paymentReversaldownloadIcon"
                  ></img>
                  Download Sample File
                </button>
              </div> */}
            </div>
            <Dropzone
              onDrop={onDrop}
              multiple={false}
              accept={{
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"]
              }}
              // accept={{
              //   "text/csv": [".csv", ".txt"],
              // }}
              // disabled={disabled}
            >
              {({ getRootProps, getInputProps }) => (
                <div
                  {...getRootProps({
                    className:
                      "CaseDetails_DisbursmentCaseWrapper_UploadWrapper d-flex align-items-center justify-content-center",
                  })}
                >
                  <input {...getInputProps()} />
                  <div className="d-flex justify-content-center flex-wrap">
                    {/* <UploadIcon /> */}
                    <div className="d-flex gap-2 uploadContent">
                      {fileData[0]?.name && (
                        <img
                          src={file}
                          className="CaseDetails_DisbursmentCaseWrapper_UploadWrapper_uploadIcon mb-2"
                          alt="upload icon"
                        ></img>
                      )}

                      {uploadPercent > 0 && uploadPercent < 100 ? (
                        `${uploadPercent}% Uploaded`
                      ) : fileData[0]?.name ? (
                        <div className="d-flex flex-wrap">
                          <p className="mt-2">{fileData[0]?.name}</p>
                          <img
                            src={deleteIcon}
                            className=" mb-2"
                            alt="upload icon"
                            onClick={() => {
                              setFileData([]);
                              setFileKey("");
                            }}
                          ></img>
                        </div>
                      ) : (
                        <>
                          <img
                            src={uploadIcon}
                            className="CaseDetails_DisbursmentCaseWrapper_UploadWrapper_uploadIcon mb-2"
                            alt="upload icon"
                          ></img>
                          <p className="CaseDetails_DisbursmentCaseWrapper_UploadWrapper_dragTxt CaseDetails_DisbursmentCaseWrapper_label mt-2">
                            <b>
                              Drag your File here Or &nbsp;
                              <span className="CaseDetails_DisbursmentCaseWrapper_UploadWrapper_browseTxt">
                                Browse
                              </span>
                            </b>
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </Dropzone>
          </div>
          <div className="d-flex gap-4 mb-3 mt-3 ps-4">
            {sponserbankvalue && fileData ? (
              <div>
                <Button
                  value="Cancel"
                  onClick={() => clearData()}
                  role="secondary"
                />
              </div>
            ) : (
              ""
            )}

            <div>
              <Button
                onClick={() => onSubmit()}
                value="Submit"
                role="primary"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default StandaloneFileUpload;
