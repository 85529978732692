import "./Routes.scss"
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Collapse, Nav, Navbar } from "react-bootstrap";
import { ReactComponent as DashboardIcon } from "../../../Images/sidebar/dashboard.svg";
import { ReactComponent as CheckListIcon } from "../../../Images/sidebar/check-list.svg";
import { ReactComponent as PayableIcon } from "../../../Images/sidebar/payable.svg";
import { ReactComponent as SubscriptionIcon } from "../../../Images/sidebar/subscription.svg";
import { ReactComponent as WalletIcon } from "../../../Images/sidebar/wallet.svg";
import { ReactComponent as TransactionIcon } from "../../../Images/sidebar/transaction.svg";
import { ReactComponent as UpIcon } from "../../../Images/sidebar/chevron-up.svg";
import { ReactComponent as DownIcon } from "../../../Images/sidebar/chevron-down.svg";
import { ROUTE_PATH } from "../../../constants/Routes";
import { useDispatch, useSelector } from "react-redux";
import {
  mandateDetails,
  systemDetails,
  customerDetails,
} from "../../../redux/reducer/SingleMandateReducer";
import { showBar } from "../../../redux/reducer/sideBarStatus";

function Routes() {
  const [debitMenu, setDebitMenu] = useState(false);
  const [paymentMenu, setPaymentMenu] = useState(false);
  const [autoPaymentMenu, setAutoPaymentMenu] = useState(false);
  const [partnerpaymentMenu, setPartnerPaymentMenu] = useState(false);
  const [accessData, setAccessData] = useState([]);
  const [menuName, setMenuName] = useState("Dashboard");
  const [currentMenu, setCurrentMenu] = useState("");
  const [enachBtn, setEnach] = useState(false);
  const [accountValidation, setAccountValidation] = useState(false);
  const [masters, setMasters] = useState(false);
  const [systemMaster2, setSystemMaster2] = useState(false);
  const [systemMaster, setSystemMaster] = useState(false);
  const [reports, setReports] = useState(false);
  const [chequeMatser, setChequeMaster] = useState(false);
  const [apiReports, setApiReports] = useState(false);
  const [standalone, setStandalone] = useState(false);

  const closeRemainingTabs = (data) => {
    // console.log("data", data);
    switch (data) {
      case "dashboard":
        setEnach(false);
        setDebitMenu(false);
        setAccountValidation(false);
        setPaymentMenu(false);
        setPartnerPaymentMenu(false);
        setMasters(false);
        setSystemMaster2(false);
        setSystemMaster(false);
        setReports(false);
        break;
      case "enach":
        setDebitMenu(false);
        setEnach(!enachBtn);
        setAccountValidation(false);
        setPaymentMenu(false);
        setPartnerPaymentMenu(false);
        setMasters(false);
        setSystemMaster2(false);
        setSystemMaster(false);
        setReports(false);
        break;
      case "debitTransaction":
        setDebitMenu(!debitMenu);
        setEnach(false);
        setAccountValidation(false);
        setPaymentMenu(false);
        setPartnerPaymentMenu(false);
        setMasters(false);
        setSystemMaster2(false);
        setSystemMaster(false);
        setReports(false);
        break;
      case "accountValidation":
        setAccountValidation(!accountValidation);
        setEnach(false);
        setDebitMenu(false);
        setPaymentMenu(false);
        setPartnerPaymentMenu(false);
        setMasters(false);
        setSystemMaster2(false);
        setSystemMaster(false);
        setReports(false);
        break;
      case "payment":
        setPaymentMenu(!paymentMenu);
        setEnach(false);
        setDebitMenu(false);
        setAccountValidation(false);
        setPartnerPaymentMenu(false);
        setMasters(false);
        setSystemMaster2(false);
        setSystemMaster(false);
        setReports(false);
        break;
      case "partnerpayments":
        setPartnerPaymentMenu(!partnerpaymentMenu);
        setEnach(false);
        setDebitMenu(false);
        setAccountValidation(false);
        setPaymentMenu(false);
        setMasters(false);
        setSystemMaster2(false);
        setSystemMaster(false);
        setReports(false);
        break;
      case "users":
        setMasters(!masters);
        setEnach(false);
        setDebitMenu(false);
        setAccountValidation(false);
        setPaymentMenu(false);
        setPartnerPaymentMenu(false);
        setSystemMaster2(false);
        setSystemMaster(false);
        setReports(false);
        break;
      case "systemMasters":
        setSystemMaster(!systemMaster);
        setEnach(false);
        setDebitMenu(false);
        setAccountValidation(false);
        setPaymentMenu(false);
        setPartnerPaymentMenu(false);
        setMasters(false);
        setSystemMaster2(false);
        setReports(false);
        break;
      case "systemMasters2":
        setSystemMaster2(!systemMaster2);
        setEnach(false);
        setDebitMenu(false);
        setAccountValidation(false);
        setPaymentMenu(false);
        setPartnerPaymentMenu(false);
        setMasters(false);
        setSystemMaster(false);
        setReports(false);
        break;
      case "reports":
        setReports(!reports);
        setEnach(false);
        setDebitMenu(false);
        setAccountValidation(false);
        setPaymentMenu(false);
        setPartnerPaymentMenu(false);
        setMasters(false);
        setSystemMaster2(false);
        setSystemMaster(false);
        break;
      case "autopayment":
        setAutoPaymentMenu(!autoPaymentMenu);
        setPaymentMenu(false);
        setEnach(false);
        setDebitMenu(false);
        setAccountValidation(false);
        setPartnerPaymentMenu(false);
        setMasters(false);
        setSystemMaster2(false);
        setSystemMaster(false);
        setReports(false);
        break;
      case "ChequeMaster":
        setChequeMaster(!chequeMatser);
        setAutoPaymentMenu(false);
        setPaymentMenu(false);
        setEnach(false);
        setDebitMenu(false);
        setAccountValidation(false);
        setPartnerPaymentMenu(false);
        setMasters(false);
        setSystemMaster2(false);
        setSystemMaster(false);
        setReports(false);
        break;
      case "apiReports":
        setApiReports(!apiReports)
        setChequeMaster(false);
        setAutoPaymentMenu(false);
        setPaymentMenu(false);
        setEnach(false);
        setDebitMenu(false);
        setAccountValidation(false);
        setPartnerPaymentMenu(false);
        setMasters(false);
        setSystemMaster2(false);
        setSystemMaster(false);
        setReports(false);
        break;

      case "standalone": 
        setStandalone(!standalone)
        setApiReports(false)
        setChequeMaster(false);
        setAutoPaymentMenu(false);
        setPaymentMenu(false);
        setEnach(false);
        setDebitMenu(false);
        setAccountValidation(false);
        setPartnerPaymentMenu(false);
        setMasters(false);
        setSystemMaster2(false);
        setSystemMaster(false);
        setReports(false);
      default:
      // code block
    }
  };

  const dispatch = useDispatch();

  const loginState = useSelector((state) => state.login);

  const dispatchActions = () => {
    dispatch(systemDetails({}));
    dispatch(mandateDetails({}));
    dispatch(customerDetails({}));
  };

  useEffect(() => {
    let userData = loginState?.userData;
    console.log('userdata---------------->>>>>',userData)
    if (userData?.length) {
      let tempUser = [...userData];
      tempUser.push("Exception", "Callback");
      console.log(tempUser, "tempUser");
      if (loginState.decodedData.case_approval_flag == "1") {
        tempUser.push("case_details_approval");
      }
      setAccessData([...tempUser]);
    }
  }, []);
  return (
    <div className="sidebar-body" id="Sidebar-Body">
      <Nav
        as="ul"
        bsPrefix="nav-list"
        defaultActiveKey={"Dashboard"}
        onSelect={(selectedKey) => setMenuName(selectedKey)}
      >
        {/* {accessData.includes("bill_upload" || "api_user_registration") && ( */}
        <Nav.Item
          as="li"
          onClick={() => {
            setCurrentMenu("Dashboard")
          }}>
          <Nav.Link
            as={Link}
            to={ROUTE_PATH.HOME}
            eventKey={"Dashboard"}
            bsPrefix={`nav-list-item ${currentMenu === "Dashboard" ? "subActive" : ""}`}>
            <div
              className="d-flex gap-2"
              onClick={() => {
                closeRemainingTabs("dashboard")
              }}>
              <DashboardIcon />
              <span className="menu-name">Dashboard</span>
            </div>
          </Nav.Link>
        </Nav.Item>

        {/* )} */}
        {(accessData.includes("View_Mandate_Registration") ||
          accessData.includes("Single_Mandate_Registration") ||
          accessData.includes("Bulk_Mandate_Registration") ||
          accessData.includes("Swap_Reversal_Upload") ||
          accessData.includes("Swap_Mandate_Block") ||
          accessData.includes("Enach_Statuses") ||
          accessData.includes("Enach_Reports")) && (
          <Nav.Item as="li">
            <Nav.Link bsPrefix="nav-list-item">
              <div className="d-grid w-100">
                <div
                  onClick={() => {
                    closeRemainingTabs("enach");
                    setCurrentMenu("ENACH");
                  }}
                  className="w-100 d-flex justify-content-between"
                >
                  <div className="d-flex gap-2">
                    <SubscriptionIcon />
                    <span className="menu-name">ENACH</span>
                  </div>
                  {enachBtn ? <UpIcon /> : <DownIcon />}
                </div>
                <Collapse in={enachBtn}>
                  <div>
                    <ul className="nav-sub-menu">
                      {accessData.includes(
                        "View_Mandate_Registration" ||
                          "Single_Mandate_Registration"
                      ) && (
                        <Nav.Item as="li">
                          <Nav.Link
                            eventKey={"Mandate Registration"}
                            bsPrefix={`nav-list-item ${
                              currentMenu === "Mandate Registration" ||
                              currentMenu === "Single" ||
                              currentMenu === "Bulk Upload"
                                ? "active"
                                : ""
                            }`}
                          >
                            <div className="d-grid w-100">
                              <div
                                onClick={() => {
                                  currentMenu === "Mandate Registration" ||
                                  currentMenu === "Single" ||
                                  currentMenu === "Bulk Upload"
                                    ? setCurrentMenu("")
                                    : setCurrentMenu("Mandate Registration");
                                }}
                                className="w-100 d-flex justify-content-between"
                              >
                                <Nav.Link
                                  eventKey={"Mandate Registration"}
                                  as={Link}
                                  to={ROUTE_PATH.MANDATE}
                                  bsPrefix={`nav-list-item ${
                                    currentMenu === "Mandate Registration"
                                      ? "subActive"
                                      : ""
                                  }`}
                                >
                                  <div className="d-flex gap-2">
                                    <CheckListIcon />
                                    <span className="menu-name">
                                      Mandate Registration
                                    </span>
                                  </div>
                                </Nav.Link>
                                {currentMenu === "Mandate Registration" ||
                                currentMenu === "Single" ||
                                currentMenu === "Bulk Upload" ? (
                                  <UpIcon />
                                ) : (
                                  <DownIcon />
                                )}
                              </div>
                              <Collapse
                                in={
                                  currentMenu === "Mandate Registration" ||
                                  currentMenu === "Single" ||
                                  currentMenu === "Bulk Upload"
                                }
                              >
                                <div>
                                  <ul className="nav-sub-menu">
                                    {accessData.includes(
                                      "View_Mandate_Registration" ||
                                        "Single_Mandate_Registration"
                                    ) && (
                                      <Nav.Item as="li">
                                        <Nav.Link
                                          eventKey={"Single"}
                                          bsPrefix={`nav-list-item ${
                                            currentMenu === "Single" ||
                                            currentMenu === "Create Mandate" ||
                                            currentMenu === "View Mandate"
                                              ? "subActive"
                                              : ""
                                          }`}
                                        >
                                          <div className="d-grid w-100">
                                            <div
                                              onClick={() => {
                                                currentMenu === "Single" ||
                                                currentMenu ===
                                                  "Create Mandate" ||
                                                currentMenu === "View Mandate"
                                                  ? setCurrentMenu(
                                                      "Mandate Registration"
                                                    )
                                                  : setCurrentMenu("Single");
                                              }}
                                              className="w-100 d-flex justify-content-between"
                                            >
                                              <Nav.Link
                                                eventKey={"Single"}
                                                as={Link}
                                                to={ROUTE_PATH.SINGLE_MANDATE}
                                                onClick={() =>
                                                  dispatchActions()
                                                }
                                                bsPrefix={`nav-list-item ${
                                                  currentMenu === "Single"
                                                    ? "subActive"
                                                    : ""
                                                }`}
                                              >
                                                <div className="d-flex gap-2">
                                                  <CheckListIcon />
                                                  <span className="menu-name">
                                                    Single
                                                  </span>
                                                </div>
                                              </Nav.Link>
                                              {currentMenu === "Single" ||
                                              currentMenu ===
                                                "Create Mandate" ||
                                              currentMenu === "View Mandate" ? (
                                                <UpIcon />
                                              ) : (
                                                <DownIcon />
                                              )}
                                            </div>
                                            <Collapse
                                              in={
                                                currentMenu === "Single" ||
                                                currentMenu ===
                                                  "Create Mandate" ||
                                                currentMenu === "View Mandate"
                                              }
                                            >
                                              <div>
                                                <ul className="nav-sub-menu">
                                                  {accessData.includes(
                                                    "Single_Mandate_Registration"
                                                  ) && (
                                                    <Nav.Item
                                                      as="li"
                                                      onClick={() =>
                                                        currentMenu ===
                                                          "Single" ||
                                                        currentMenu ===
                                                          "Create Mandate" ||
                                                        currentMenu ===
                                                          "View Mandate"
                                                          ? setCurrentMenu(
                                                              "Single"
                                                            )
                                                          : setCurrentMenu(
                                                              "Create Mandate"
                                                            )
                                                      }
                                                    >
                                                      <Nav.Link
                                                        eventKey={
                                                          "Create Mandate"
                                                        }
                                                        as={Link}
                                                        to={
                                                          ROUTE_PATH.SINGLE_MANDATE
                                                        }
                                                        onClick={() =>
                                                          dispatchActions()
                                                        }
                                                        state={{ step: 0 }}
                                                        bsPrefix={`nav-list-item ${
                                                          currentMenu ===
                                                          "Create Mandate"
                                                            ? "subActive"
                                                            : ""
                                                        }`}
                                                      >
                                                        <div className="d-flex gap-2">
                                                          <CheckListIcon />
                                                          <span className="menu-name">
                                                            Create Mandate
                                                          </span>
                                                        </div>
                                                      </Nav.Link>
                                                    </Nav.Item>
                                                  )}
                                                  {accessData.includes(
                                                    "View_Mandate_Registration"
                                                  ) && (
                                                    <Nav.Item
                                                      as="li"
                                                      onClick={() =>
                                                        currentMenu ===
                                                          "Single" ||
                                                        currentMenu ===
                                                          "Create Mandate" ||
                                                        currentMenu ===
                                                          "View Mandate"
                                                          ? setCurrentMenu(
                                                              "Single"
                                                            )
                                                          : setCurrentMenu(
                                                              "View Mandate"
                                                            )
                                                      }
                                                    >
                                                      <Nav.Link
                                                        eventKey={
                                                          "View Mandate"
                                                        }
                                                        as={Link}
                                                        to={
                                                          ROUTE_PATH.VIEW_MANDATE
                                                        }
                                                        bsPrefix={`nav-list-item ${
                                                          currentMenu ===
                                                          "View Mandate"
                                                            ? "subActive"
                                                            : ""
                                                        }`}
                                                      >
                                                        <div className="d-flex gap-2">
                                                          <CheckListIcon />
                                                          <span className="menu-name">
                                                            View Mandate
                                                          </span>
                                                        </div>
                                                      </Nav.Link>
                                                    </Nav.Item>
                                                  )}
                                                </ul>
                                              </div>
                                            </Collapse>
                                          </div>
                                        </Nav.Link>
                                      </Nav.Item>
                                    )}
                                    {accessData.includes(
                                      "Bulk_Mandate_Registration"
                                    ) && (
                                      <Nav.Item
                                        as="li"
                                        onClick={() =>
                                          setCurrentMenu("Bulk Upload")
                                        }
                                      >
                                        <Nav.Link
                                          eventKey={"Bulk Upload"}
                                          as={Link}
                                          to={ROUTE_PATH.BULK_MANDATE}
                                          bsPrefix={`nav-list-item ${
                                            currentMenu === "Bulk Upload"
                                              ? "subActive"
                                              : ""
                                          }`}
                                        >
                                          <div className="d-flex gap-2">
                                            <CheckListIcon />
                                            <span className="menu-name">
                                              Bulk Upload
                                            </span>
                                          </div>
                                        </Nav.Link>
                                      </Nav.Item>
                                    )}
                                  </ul>
                                </div>
                              </Collapse>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      )}
                      {(accessData.includes("Swap_Reversal_Upload") ||
                        accessData.includes("Swap_Mandate_Block")) && (
                        <Nav.Item as="li">
                          <Nav.Link
                            eventKey={"Swap"}
                            bsPrefix={`nav-list-item ${
                              currentMenu === "SWAP" ||
                              currentMenu === "Swap Reversal Upload" ||
                              currentMenu === "Mandate Block Cases - SWAP"
                                ? "subActive"
                                : ""
                            }`}
                          >
                            <div className="d-grid w-100">
                              <div
                                onClick={() => {
                                  currentMenu === "SWAP" ||
                                  currentMenu === "Swap Reversal Upload" ||
                                  currentMenu === "Mandate Block Cases - SWAP"
                                    ? setCurrentMenu("")
                                    : setCurrentMenu("SWAP");
                                }}
                                className="w-100 d-flex justify-content-between"
                              >
                                <Nav.Link
                                  eventKey={"SWAP"}
                                  as={Link}
                                  to={ROUTE_PATH.SWAP_REVERSAL_LIST}
                                  bsPrefix={`nav-list-item ${
                                    currentMenu === "SWAP" ? "subActive" : ""
                                  }`}
                                >
                                  <div className="d-flex gap-2">
                                    <CheckListIcon />
                                    <span className="menu-name">SWAP</span>
                                  </div>
                                </Nav.Link>
                                {currentMenu === "SWAP" ||
                                currentMenu === "Swap Reversal Upload" ||
                                currentMenu === "Mandate Block Cases - SWAP" ? (
                                  <UpIcon />
                                ) : (
                                  <DownIcon />
                                )}
                              </div>
                              <Collapse
                                in={
                                  currentMenu === "SWAP" ||
                                  currentMenu === "Swap Reversal Upload" ||
                                  currentMenu === "Mandate Block Cases - SWAP"
                                }
                              >
                                <div>
                                  <ul className="nav-sub-menu">
                                    {accessData.includes(
                                      "Swap_Reversal_Upload"
                                    ) && (
                                      <Nav.Item
                                        as="li"
                                        onClick={() =>
                                          setCurrentMenu("Swap Reversal Upload")
                                        }
                                      >
                                        <Nav.Link
                                          eventKey={"Swap Reversal Upload"}
                                          as={Link}
                                          to={ROUTE_PATH.SWAP_UPLOAD}
                                          bsPrefix={`nav-list-item ${
                                            currentMenu ===
                                            "Swap Reversal Upload"
                                              ? "subActive"
                                              : ""
                                          }`}
                                        >
                                          <div className="d-flex gap-2">
                                            <CheckListIcon />
                                            <span className="menu-name">
                                              Swap Reversal Upload
                                            </span>
                                          </div>
                                        </Nav.Link>
                                      </Nav.Item>
                                    )}

                                    {accessData.includes(
                                      "Swap_Mandate_Block"
                                    ) && (
                                      <Nav.Item
                                        as="li"
                                        onClick={() =>
                                          setCurrentMenu(
                                            "Mandate Block Cases - SWAP"
                                          )
                                        }
                                      >
                                        <Nav.Link
                                          eventKey={
                                            "Mandate Block Cases - SWAP"
                                          }
                                          as={Link}
                                          to={ROUTE_PATH.SWAP_LIST}
                                          bsPrefix={`nav-list-item ${
                                            currentMenu ===
                                            "Mandate Block Cases - SWAP"
                                              ? "subActive"
                                              : ""
                                          }`}
                                        >
                                          <div className="d-flex gap-2">
                                            <CheckListIcon />
                                            <span className="menu-name">
                                              Mandate Block Cases - SWAP
                                            </span>
                                          </div>
                                        </Nav.Link>
                                      </Nav.Item>
                                    )}
                                  </ul>
                                </div>
                              </Collapse>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      )}
                      {accessData.includes("Enach_Statuses") && (
                        <Nav.Item
                          as="li"
                          onClick={() => setCurrentMenu("Status")}
                        >
                          <Nav.Link
                            eventKey={"Status"}
                            as={Link}
                            to={ROUTE_PATH.STATUS}
                            bsPrefix={`nav-list-item ${
                              currentMenu === "Status" ? "subActive" : ""
                            }`}
                          >
                            <div className="d-flex gap-2">
                              <CheckListIcon />
                              <span className="menu-name">Status</span>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      )}
                      {accessData.includes("Enach_Reports") && (
                        <Nav.Item
                          as="li"
                          onClick={() => setCurrentMenu("Enach Reports")}
                        >
                          <Nav.Link
                            eventKey={"Enach Reports"}
                            as={Link}
                            to={ROUTE_PATH.REPORT}
                            bsPrefix={`nav-list-item ${
                              currentMenu === "Enach Reports" ? "subActive" : ""
                            }`}
                          >
                            <div className="d-flex gap-2">
                              <CheckListIcon />
                              <span className="menu-name">Reports</span>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      )}
                    </ul>
                  </div>
                </Collapse>
              </div>
            </Nav.Link>
          </Nav.Item>
        )}

        {(accessData.includes("DT_Merge") ||
          accessData.includes("DT_Demerge") ||
          accessData.includes("DT_Presentation") ||
          accessData.includes("DT_Representaion") ||
          accessData.includes("DT_BillUpload") ||
          accessData.includes("DT_Banking_Report") ||
          accessData.includes("Sftp_Upload")) && (
          <Nav.Item as="li">
            <Nav.Link eventKey={"Master Data"} bsPrefix="nav-list-item">
              <div className="d-grid w-100">
                <div
                  onClick={() => {
                    closeRemainingTabs("debitTransaction");
                    setCurrentMenu("Debit Transaction");
                  }}
                  className="w-100 d-flex justify-content-between"
                >
                  <div className="d-flex gap-2">
                    <TransactionIcon />
                    <span className="menu-name">Debit Transaction</span>
                  </div>
                  {debitMenu ? <UpIcon /> : <DownIcon />}
                </div>
                <Collapse in={debitMenu}>
                  <div>
                    <ul className="nav-sub-menu">
                      {accessData.includes("DT_Merge") && (
                        <Nav.Item
                          as="li"
                          onClick={() => setCurrentMenu("Merge")}
                        >
                          <Nav.Link
                            eventKey={"Merge"}
                            as={Link}
                            to={ROUTE_PATH.DEBIT_TRANSACTION_MERGE}
                            onClick={() => {
                              dispatch(showBar(false));
                            }}
                            bsPrefix={`nav-list-item ${
                              currentMenu === "Merge" ? "subActive" : ""
                            }`}
                          >
                            <div className="d-flex gap-2">
                              <CheckListIcon />
                              <span className="menu-name">Merge</span>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      )}
                      {accessData.includes("DT_Demerge") && (
                        <Nav.Item
                          as="li"
                          onClick={() => setCurrentMenu("Demerge")}
                        >
                          <Nav.Link
                            eventKey={"Demerge"}
                            as={Link}
                            to={ROUTE_PATH.DEBIT_TRANSACTION_DEMERGE_STATUS}
                            onClick={() => {
                              dispatch(showBar(false));
                            }}
                            bsPrefix={`nav-list-item ${
                              currentMenu === "Demerge" ? "subActive" : ""
                            }`}
                          >
                            <div className="d-flex gap-2">
                              <CheckListIcon />
                              <span className="menu-name">Demerge</span>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      )}
                      {accessData.includes("DT_Presentation") && (
                        <Nav.Item
                          as="li"
                          onClick={() => {
                            setCurrentMenu("Presentation");
                          }}
                        >
                          <Nav.Link
                            eventKey={"Presentation"}
                            as={Link}
                            to={ROUTE_PATH.DEBIT_TRANSACTION_PRESENTATION}
                            onClick={() => {
                              dispatch(showBar(false));
                            }}
                            bsPrefix={`nav-list-item ${
                              currentMenu === "Presentation" ? "subActive" : ""
                            }`}
                          >
                            <div className="d-flex gap-2">
                              <CheckListIcon />
                              <span className="menu-name">Presentation</span>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      )}
                      {accessData.includes("DT_BillUpload") && (
                        <Nav.Item
                          as="li"
                          onClick={() => setCurrentMenu("RPT Provision Upload")}
                        >
                          <Nav.Link
                            eventKey={"RPT Provision Upload"}
                            as={Link}
                            to={
                              ROUTE_PATH.DEBIT_TRANSACTION_REPRESENTATION_STATUS
                            }
                            onClick={() => {
                              dispatch(showBar(false));
                            }}
                            bsPrefix={`nav-list-item ${
                              currentMenu === "RPT Provision Upload"
                                ? "subActive"
                                : ""
                            }`}
                          >
                            <div className="d-flex gap-2">
                              <CheckListIcon />
                              <span className="menu-name">
                                RPT Provision Upload
                              </span>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      )}
                      {accessData.includes("DT_BillUpload") && (
                        <Nav.Item
                          as="li"
                          onClick={() => setCurrentMenu("RPT Provision Upload")}
                        >
                          <Nav.Link
                            eventKey={"RPT Provision Upload"}
                            as={Link}
                            to={ROUTE_PATH.RPT_OTU}
                            onClick={() => {
                              dispatch(showBar(false));
                            }}
                            bsPrefix={`nav-list-item ${
                              currentMenu === "RPT Provision Upload"
                                ? "subActive"
                                : ""
                            }`}
                          >
                            <div className="d-flex gap-2">
                              <CheckListIcon />
                              <span className="menu-name">RPT OTU</span>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      )}
                      {accessData.includes("DT_BillUpload") && (
                        <Nav.Item
                          as="li"
                          onClick={() => setCurrentMenu("Bill Upload")}
                        >
                          <Nav.Link
                            eventKey={"Bill Upload"}
                            as={Link}
                            to={ROUTE_PATH.DEBIT_TRANSACTION_BILLUPLOAD_STATUS}
                            onClick={() => {
                              dispatch(showBar(false));
                            }}
                            bsPrefix={`nav-list-item ${
                              currentMenu === "Bill Upload" ? "subActive" : ""
                            }`}
                          >
                            <div className="d-flex gap-2">
                              <CheckListIcon />
                              <span className="menu-name">Bill Upload</span>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      )}
                      {accessData.includes("DT_BillUpload") && (
                        <Nav.Item
                          as="li"
                          onClick={() => setCurrentMenu("Sftp Upload")}
                        >
                          <Nav.Link
                            eventKey={"Sftp Upload"}
                            as={Link}
                            to={ROUTE_PATH.SFTPUPLOAD}
                            onClick={() => {
                              dispatch(showBar(false));
                            }}
                            bsPrefix={`nav-list-item ${
                              currentMenu === "Sftp Upload" ? "subActive" : ""
                            }`}
                          >
                            <div className="d-flex gap-2">
                              <CheckListIcon />
                              <span className="menu-name">Sftp Upload</span>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      )}
                      {accessData.includes("DT_BillUpload") && (
                        <Nav.Item
                          as="li"
                          onClick={() => setCurrentMenu("Registration Demerge")}
                        >
                          <Nav.Link
                            eventKey={"Registration Demerge"}
                            as={Link}
                            to={ROUTE_PATH.UPI_EXECUTION_UPDATE}
                            onClick={() => {
                              dispatch(showBar(false))
                            }}
                            bsPrefix={`nav-list-item ${
                              currentMenu === "Registration Demerge" ? "subActive" : ""
                            }`}>
                            <div className="d-flex gap-2">
                              <CheckListIcon />
                              <span className="menu-name">Registration Demerge</span>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      )}
                      {accessData.includes("DT_BillUpload") && (
                        <Nav.Item as="li" onClick={() => setCurrentMenu("UPI Representation Exclusive")}>
                          <Nav.Link
                            eventKey={"UPI Representation Exclusive"}
                            as={Link}
                            to={ROUTE_PATH.UPI_EXECUTION_UPDATE}
                            onClick={() => {
                              dispatch(showBar(false))
                            }}
                            bsPrefix={`nav-list-item ${
                              currentMenu === "UPI Representation Exclusive" ? "subActive" : ""
                            }`}>
                            <div className="d-flex gap-2">
                              <CheckListIcon />
                              <span className="menu-name">UPI Representation Exclusive</span>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      )}
                      {accessData.includes("DT_Banking_Report") && (
                        <Nav.Item as="li">
                          <Nav.Link
                            eventKey={"Reports"}
                            bsPrefix={`nav-list-item ${
                              currentMenu === "Reports" ||
                              currentMenu === "Mandate Reg. Revarsal SFTP" ||
                              currentMenu === "Registration & Banking" ||
                              currentMenu === "HDFC" ||
                              currentMenu === "INDUSIND Report"
                                ? "subActive"
                                : ""
                            }`}
                          >
                            <div className="d-grid w-100">
                              <div
                                onClick={() => {
                                  currentMenu === "Reports" ||
                                  currentMenu ===
                                    "Mandate Reg. Revarsal SFTP" ||
                                  currentMenu === "Registration & Banking" ||
                                  currentMenu === "HDFC" ||
                                  currentMenu === "INDUSIND Report"
                                    ? setCurrentMenu("")
                                    : setCurrentMenu("Reports");
                                }}
                                className="w-100 d-flex justify-content-between"
                              >
                                <div className="d-flex gap-2">
                                  <CheckListIcon />
                                  <span className="menu-name">Reports</span>
                                </div>
                                {currentMenu === "Reports" ||
                                currentMenu === "Mandate Reg. Revarsal SFTP" ||
                                currentMenu === "Registration & Banking" ||
                                currentMenu === "HDFC" ||
                                currentMenu === "INDUSIND Report" ? (
                                  <UpIcon />
                                ) : (
                                  <DownIcon />
                                )}
                              </div>
                              <Collapse
                                in={
                                  currentMenu === "Reports" ||
                                  currentMenu ===
                                    "Mandate Reg. Revarsal SFTP" ||
                                  currentMenu === "Registration & Banking" ||
                                  currentMenu === "HDFC" ||
                                  currentMenu === "INDUSIND Report"
                                }
                              >
                                <div>
                                  <ul className="nav-sub-menu">
                                    <Nav.Item
                                      as="li"
                                      onClick={() =>
                                        setCurrentMenu("Registration & Banking")
                                      }
                                    >
                                      <Nav.Link
                                        eventKey={"Registration & Banking"}
                                        as={Link}
                                        to={
                                          ROUTE_PATH.DEBIT_TRANSACTION_REPORTS_REGISTRATION_BANKING
                                        }
                                        bsPrefix={`nav-list-item ${
                                          currentMenu ===
                                          "Registration & Banking"
                                            ? "subActive"
                                            : ""
                                        }`}
                                      >
                                        <div className="d-flex gap-2">
                                          <CheckListIcon />
                                          <span className="menu-name">
                                            Registration & Banking
                                          </span>
                                        </div>
                                      </Nav.Link>
                                    </Nav.Item>
                                    {/* {accessData?.mandateRegRevSftpStatus && (
                                    <Nav.Item as="li">
                                      <Nav.Link
                                        eventKey={"Mandate Reg. Revarsal SFTP"}
                                        bsPrefix={`nav-list-item ${
                                          currentMenu === "Mandate Reg. Revarsal SFTP" ||
                                          currentMenu === "HDFC" ||
                                          currentMenu === "INDUSIND Report"
                                            ? "subActive"
                                            : ""
                                        }`}>
                                        <div className="d-grid w-100">
                                          <div
                                            onClick={() => {
                                              currentMenu === "Mandate Reg. Revarsal SFTP" ||
                                              currentMenu === "HDFC" ||
                                              currentMenu === "INDUSIND Report"
                                                ? setCurrentMenu("")
                                                : setCurrentMenu("Mandate Reg. Revarsal SFTP")
                                            }}
                                            className="w-100 d-flex justify-content-between">
                                            <div className="d-flex gap-2">
                                              <CheckListIcon />
                                              <span className="menu-name">
                                                Mandate Reg. Revarsal SFTP
                                              </span>
                                            </div>
                                            {currentMenu === "Mandate Reg. Revarsal SFTP" ||
                                            currentMenu === "HDFC" ||
                                            currentMenu === "INDUSIND Report" ? (
                                              <UpIcon />
                                            ) : (
                                              <DownIcon />
                                            )}
                                          </div>
                                          <Collapse
                                            in={
                                              currentMenu === "Mandate Reg. Revarsal SFTP" ||
                                              currentMenu === "HDFC" ||
                                              currentMenu === "INDUSIND Report"
                                            }>
                                            <div>
                                              <ul className="nav-sub-menu">
                                                <Nav.Item
                                                  as="li"
                                                  onClick={() => setCurrentMenu("HDFC")}>
                                                  <Nav.Link
                                                    eventKey={"HDFC"}
                                                    as={Link}
                                                    to={
                                                      ROUTE_PATH.DEBIT_TRANSACTION_REPORTS_MANDATE_REGISTER_HDFC_REPORT
                                                    }
                                                    bsPrefix={`nav-list-item ${
                                                      currentMenu === "HDFC" ? "subActive" : ""
                                                    }`}>
                                                    <div className="d-flex gap-2">
                                                      <CheckListIcon />
                                                      <span className="menu-name">HDFC</span>
                                                    </div>
                                                  </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item
                                                  as="li"
                                                  onClick={() => setCurrentMenu("INDUSIND Report")}>
                                                  <Nav.Link
                                                    eventKey={"INDUSIND Report"}
                                                    as={Link}
                                                    to={
                                                      ROUTE_PATH.DEBIT_TRANSACTION_REPORTS_MANDATE_REGISTER_INDUSLAND_REPORT
                                                    }
                                                    bsPrefix={`nav-list-item ${
                                                      currentMenu === "INDUSIND Report"
                                                        ? "subActive"
                                                        : ""
                                                    }`}>
                                                    <div className="d-flex gap-2">
                                                      <CheckListIcon />
                                                      <span className="menu-name">
                                                        INDUSIND Report
                                                      </span>
                                                    </div>
                                                  </Nav.Link>
                                                </Nav.Item>
                                              </ul>
                                            </div>
                                          </Collapse>
                                        </div>
                                      </Nav.Link>
                                    </Nav.Item>
                                  )} */}
                                  </ul>
                                </div>
                              </Collapse>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      )}
                    </ul>
                  </div>
                </Collapse>
              </div>
            </Nav.Link>
          </Nav.Item>
        )}
        {(accessData.includes("AV_Verify_Account") ||
          accessData.includes("AV_Reports")) && (
          <Nav.Item as="li">
            <Nav.Link eventKey={"Account Master"} bsPrefix="nav-list-item">
              <div className="d-grid w-100">
                <div
                  onClick={() => {
                    closeRemainingTabs("accountValidation");
                    setCurrentMenu("Account Validation");
                  }}
                  className="w-100 d-flex justify-content-between"
                >
                  <div className="d-flex gap-2">
                    <PayableIcon />
                    <span className="menu-name">Account Validation</span>
                  </div>
                  {accountValidation ? <UpIcon /> : <DownIcon />}
                </div>
                <Collapse in={accountValidation}>
                  <div>
                    <ul className="nav-sub-menu">
                      {accessData.includes("AV_Verify_Account") && (
                        <Nav.Item
                          as="li"
                          onClick={() => setCurrentMenu("Verify Account")}
                        >
                          <Nav.Link
                            eventKey={"Verify Account"}
                            as={Link}
                            to={ROUTE_PATH.VERIFY_ACCOUNT}
                            bsPrefix={`nav-list-item ${
                              currentMenu === "Verify Account"
                                ? "subActive"
                                : ""
                            }`}
                          >
                            <div className="d-flex gap-2">
                              <CheckListIcon />
                              <span className="menu-name">Verify Account</span>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      )}
                      {accessData.includes("AV_Reports") && (
                        <Nav.Item
                          as="li"
                          onClick={() => setCurrentMenu("AV_Reports")}
                        >
                          <Nav.Link
                            eventKey={"Account Reports"}
                            as={Link}
                            to={ROUTE_PATH.ACCOUNT_VALIDATION_REPORTS}
                            bsPrefix={`nav-list-item ${
                              currentMenu === "AV_Reports" ? "subActive" : ""
                            }`}
                          >
                            <div className="d-flex gap-2">
                              <CheckListIcon />
                              <span className="menu-name">Reports</span>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      )}
                      <Nav.Item
                        as="li"
                        onClick={() => setCurrentMenu("Single Verify Status")}
                      >
                        <Nav.Link
                          eventKey={"Single Verify Status"}
                          as={Link}
                          to={ROUTE_PATH?.SINGLE_VERIFY_STATUS}
                          bsPrefix={`nav-list-item ${
                            currentMenu === "Single Verify Status"
                              ? "subActive"
                              : ""
                          }`}
                        >
                          <div className="d-flex gap-2">
                            <CheckListIcon />
                            <span className="menu-name">
                              Single Verify Status
                            </span>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                    </ul>
                  </div>
                </Collapse>
              </div>
            </Nav.Link>
          </Nav.Item>
        )}
        {(accessData.includes("Regular_Payment") ||
          accessData.includes("Regular_Payment_Checker") ||
          accessData.includes("Hold_Payment") ||
          accessData.includes("Unhold_Payment") ||
          accessData.includes("Exception_Payment") ||
          accessData.includes("INDUS_HDFC_Payment_Reports") ||
          accessData.includes("Reversal_Payment") ||
          accessData.includes("API_Status_Batch") ||
          accessData.includes("API_Status_Bulk") ||
          accessData.includes("FB_Batch_Reversal") ||
          accessData.includes("PayTm_Reversal") ||
          accessData.includes("FB_Bulk_Reversal") ||
          accessData.includes("API_Base_Reversal") ||
          accessData.includes("Payement_Reports") ||
          accessData.includes("Payment_Disbursement_Reports") ||
          accessData.includes("Payment_Disbursement_Table") ||
          accessData.includes("MM_Case_Details") ||
          accessData.includes("Move_To_Exception") ||
          accessData.includes("MM_Case_Detail_Approval")) && (
          <Nav.Item as="li">
            <Nav.Link eventKey={"Payment Master"} bsPrefix="nav-list-item">
              <div className="d-grid w-100">
                <div
                  onClick={() => {
                    closeRemainingTabs("payment");
                    setCurrentMenu("Payment");
                  }}
                  className="w-100 d-flex justify-content-between"
                >
                  <div className="d-flex gap-2">
                    <WalletIcon />
                    <span className="menu-name">Manual Payment</span>
                  </div>
                  {paymentMenu ? <UpIcon /> : <DownIcon />}
                </div>
                <Collapse in={paymentMenu}>
                  <div>
                    <ul className="nav-sub-menu">
                      {accessData.includes("Regular_Payment_Checker") && (
                        <Nav.Item
                          as="li"
                          onClick={() =>
                            setCurrentMenu("Regular Payment Checker")
                          }
                        >
                          <Nav.Link
                            eventKey={"Regular Payment Checker"}
                            as={Link}
                            to={ROUTE_PATH.REGULAR_PAYMENT_CHECKER}
                            bsPrefix={`nav-list-item ${
                              currentMenu === "Regular Payment Checker"
                                ? "subActive"
                                : ""
                            }`}
                          >
                            <div className="d-flex gap-2">
                              <CheckListIcon />
                              <span className="menu-name">Regular Payment</span>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      )}
                      {/* {accessData.includes("Regular_Payment") && (
                        <Nav.Item as="li" onClick={() => setCurrentMenu("Regular Payment")}>
                          <Nav.Link
                            eventKey={"Regular Payment"}
                            as={Link}
                            to={ROUTE_PATH.REGULAR_PAYMENT_MAKER}
                            bsPrefix={`nav-list-item ${
                              currentMenu === "Regular Payment" ? "subActive" : ""
                            }`}>
                            <div className="d-flex gap-2">
                              <CheckListIcon />
                              <span className="menu-name">Regular Payment (Maker)</span>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      )} */}
                      {accessData.includes("Hold_Payment") && (
                        <Nav.Item
                          as="li"
                          onClick={() => setCurrentMenu("Hold Payment")}
                        >
                          <Nav.Link
                            eventKey={"Hold Payment"}
                            as={Link}
                            to={ROUTE_PATH.UNHOLD_PAYMENT}
                            // to={ROUTE_PATH.HOLD_PAYMENT}
                            bsPrefix={`nav-list-item ${
                              currentMenu === "Hold Payment" ? "subActive" : ""
                            }`}
                          >
                            <div className="d-flex gap-2">
                              <CheckListIcon />
                              <span className="menu-name">Hold Payment</span>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      )}
                      {/* {accessData.includes("Unhold_Payment") && (
                        <Nav.Item
                          as="li"
                          onClick={() => setCurrentMenu("Unhold Payment")}
                        >
                          <Nav.Link
                            eventKey={"Unhold Payment"}
                            as={Link}
                            to={ROUTE_PATH.UNHOLD_PAYMENT}
                            bsPrefix={`nav-list-item ${
                              currentMenu === "Unhold Payment" ? "subActive" : ""
                            }`}
                          >
                            <div className="d-flex gap-2">
                              <CheckListIcon />
                              <span className="menu-name">Unhold Payment</span>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      )} */}
                      {accessData.includes("Exception_Payment") && (
                        <Nav.Item
                          as="li"
                          onClick={() => setCurrentMenu("Exception Payment")}
                        >
                          <Nav.Link
                            eventKey={"Exception Payment"}
                            as={Link}
                            to={ROUTE_PATH.EXCEPTION_PAYMENT}
                            bsPrefix={`nav-list-item ${
                              currentMenu === "Exception Payment"
                                ? "subActive"
                                : ""
                            }`}
                          >
                            <div className="d-flex gap-2">
                              <CheckListIcon />
                              <span className="menu-name">
                                Exception Payment
                              </span>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      )}
                      {accessData.includes("INDUS_HDFC_Payment_Reports") && (
                        <Nav.Item
                          as="li"
                          onClick={() => setCurrentMenu("INDUS/HDFC Reports")}
                        >
                          <Nav.Link
                            eventKey={"INDUS/HDFC Reports"}
                            as={Link}
                            to={ROUTE_PATH.PAYMENT_INDUSHDFC}
                            bsPrefix={`nav-list-item ${
                              currentMenu === "INDUS/HDFC Reports"
                                ? "subActive"
                                : ""
                            }`}
                          >
                            <div className="d-flex gap-2">
                              <CheckListIcon />
                              <span className="menu-name">
                                INDUS/HDFC Reports
                              </span>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      )}

                      {accessData.includes("Reversal_Payment") && (
                        <Nav.Item
                          as="li"
                          onClick={() => setCurrentMenu("Reversal")}
                        >
                          <Nav.Link
                            eventKey={"Reversal"}
                            as={Link}
                            to={ROUTE_PATH.PAYMENT_REVERSAL}
                            bsPrefix={`nav-list-item ${
                              currentMenu === "Reversal" ? "subActive" : ""
                            }`}
                          >
                            <div className="d-flex gap-2">
                              <CheckListIcon />
                              <span className="menu-name">Reversal</span>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      )}
                      {accessData.includes(
                        "API_Status_Batch" || "API_Status_Bulk"
                      ) && (
                        <Nav.Item as="li">
                          <Nav.Link
                            eventKey={"API Status"}
                            bsPrefix={`nav-list-item ${
                              currentMenu === "API Status" ||
                              currentMenu === "Batch API Status" ||
                              currentMenu === "Bulk API Status"
                                ? "subActive"
                                : ""
                            }`}
                          >
                            <div className="d-grid w-100">
                              <div
                                onClick={() => {
                                  currentMenu === "API Status" ||
                                  currentMenu === "Batch API Status" ||
                                  currentMenu === "Bulk API Status"
                                    ? setCurrentMenu("")
                                    : setCurrentMenu("API Status");
                                }}
                                className="w-100 d-flex justify-content-between"
                              >
                                <div className="d-flex gap-2">
                                  <CheckListIcon />
                                  <span className="menu-name">API Status</span>
                                </div>
                                {currentMenu === "API Status" ||
                                currentMenu === "Batch API Status" ||
                                currentMenu === "Bulk API Status" ? (
                                  <UpIcon />
                                ) : (
                                  <DownIcon />
                                )}
                              </div>
                              <Collapse
                                in={
                                  currentMenu === "API Status" ||
                                  currentMenu === "Batch API Status" ||
                                  currentMenu === "Bulk API Status"
                                }
                              >
                                <div>
                                  <ul className="nav-sub-menu">
                                    {accessData.includes(
                                      "API_Status_Batch"
                                    ) && (
                                      <Nav.Item
                                        as="li"
                                        onClick={() =>
                                          setCurrentMenu("Batch API Status")
                                        }
                                      >
                                        <Nav.Link
                                          eventKey={"Batch API Status"}
                                          as={Link}
                                          to={
                                            ROUTE_PATH.PAYMENT_SPI_STATUS_BATCH
                                          }
                                          bsPrefix={`nav-list-item ${
                                            currentMenu === "Batch API Status"
                                              ? "subActive"
                                              : ""
                                          }`}
                                        >
                                          <div className="d-flex gap-2">
                                            <CheckListIcon />
                                            <span className="menu-name">
                                              Batch
                                            </span>
                                          </div>
                                        </Nav.Link>
                                      </Nav.Item>
                                    )}
                                    {accessData.includes("API_Status_Bulk") && (
                                      <Nav.Item
                                        as="li"
                                        onClick={() =>
                                          setCurrentMenu("Bulk API Status")
                                        }
                                      >
                                        <Nav.Link
                                          eventKey={"Bulk API Status"}
                                          as={Link}
                                          to={
                                            ROUTE_PATH.PAYMENT_API_STATUS_BULK
                                          }
                                          bsPrefix={`nav-list-item ${
                                            currentMenu === "Bulk API Status"
                                              ? "subActive"
                                              : ""
                                          }`}
                                        >
                                          <div className="d-flex gap-2">
                                            <CheckListIcon />
                                            <span className="menu-name">
                                              Bulk
                                            </span>
                                          </div>
                                        </Nav.Link>
                                      </Nav.Item>
                                    )}
                                  </ul>
                                </div>
                              </Collapse>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      )}
                      {accessData.includes(
                        "FB_Batch_Reversal" ||
                          "FB_Bulk_Reversal" ||
                          "API_Base_Reversal" ||
                          "PayTm_Reversal"
                      ) && (
                        <Nav.Item as="li">
                          <Nav.Link
                            eventKey={"Reversal Status"}
                            bsPrefix={`nav-list-item ${
                              currentMenu === "Reversal Status" ||
                              currentMenu === "PayTm Based" ||
                              currentMenu === "File Based - Batch" ||
                              currentMenu === "File Based - Bulk" ||
                              currentMenu === "API Based"
                                ? "subActive"
                                : ""
                            }`}
                          >
                            <div className="d-grid w-100">
                              <div
                                onClick={() => {
                                  currentMenu === "Reversal Status" ||
                                  currentMenu === "File Based - Batch" ||
                                  currentMenu === "File Based - Bulk" ||
                                  currentMenu === "PayTm Based" ||
                                  currentMenu === "API Based"
                                    ? setCurrentMenu("")
                                    : setCurrentMenu("Reversal Status");
                                }}
                                className="w-100 d-flex justify-content-between"
                              >
                                <div className="d-flex gap-2">
                                  <CheckListIcon />
                                  <span className="menu-name">
                                    Reversal Status
                                  </span>
                                </div>
                                {currentMenu === "Reversal Status" ||
                                currentMenu === "PayTm Based" ||
                                currentMenu === "File Based - Batch" ||
                                currentMenu === "File Based - Bulk" ||
                                currentMenu === "API Based" ? (
                                  <UpIcon />
                                ) : (
                                  <DownIcon />
                                )}
                              </div>
                              <Collapse
                                in={
                                  currentMenu === "Reversal Status" ||
                                  currentMenu === "PayTm Based" ||
                                  currentMenu === "File Based - Batch" ||
                                  currentMenu === "File Based - Bulk" ||
                                  currentMenu === "API Based"
                                }
                              >
                                <div>
                                  <ul className="nav-sub-menu">
                                    {accessData.includes(
                                      "FB_Batch_Reversal"
                                    ) && (
                                      <Nav.Item
                                        as="li"
                                        onClick={() =>
                                          setCurrentMenu("File Based - Batch")
                                        }
                                      >
                                        <Nav.Link
                                          eventKey={"File Based - Batch"}
                                          as={Link}
                                          to={
                                            ROUTE_PATH.PAYMENT_REVERSAL_STATUS_FILE_BASED_BATCH
                                          }
                                          bsPrefix={`nav-list-item ${
                                            currentMenu === "File Based - Batch"
                                              ? "subActive"
                                              : ""
                                          }`}
                                        >
                                          <div className="d-flex gap-2">
                                            <CheckListIcon />
                                            <span className="menu-name">
                                              File Based - Batch
                                            </span>
                                          </div>
                                        </Nav.Link>
                                      </Nav.Item>
                                    )}
                                    {accessData.includes("PayTm_Reversal") && (
                                      <Nav.Item
                                        as="li"
                                        onClick={() =>
                                          setCurrentMenu("PayTm Based")
                                        }
                                      >
                                        <Nav.Link
                                          eventKey={"PayTm Based"}
                                          as={Link}
                                          to={ROUTE_PATH.REVERSAL_PAYTM_BASED}
                                          bsPrefix={`nav-list-item ${
                                            currentMenu === "PayTm Based"
                                              ? "subActive"
                                              : ""
                                          }`}
                                        >
                                          <div className="d-flex gap-2">
                                            <CheckListIcon />
                                            <span className="menu-name">
                                              Paytm Based
                                            </span>
                                          </div>
                                        </Nav.Link>
                                      </Nav.Item>
                                    )}
                                    {accessData.includes(
                                      "FB_Bulk_Reversal"
                                    ) && (
                                      <Nav.Item
                                        as="li"
                                        onClick={() =>
                                          setCurrentMenu("File Based - Bulk")
                                        }
                                      >
                                        <Nav.Link
                                          eventKey={"File Based - Bulk"}
                                          as={Link}
                                          to={
                                            ROUTE_PATH.REVERSAL_File_BASED_Bulk
                                          }
                                          bsPrefix={`nav-list-item ${
                                            currentMenu === "File Based - Bulk"
                                              ? "subActive"
                                              : ""
                                          }`}
                                        >
                                          <div className="d-flex gap-2">
                                            <CheckListIcon />
                                            <span className="menu-name">
                                              File Based - Bulk
                                            </span>
                                          </div>
                                        </Nav.Link>
                                      </Nav.Item>
                                    )}
                                    {accessData.includes(
                                      "API_Base_Reversal"
                                    ) && (
                                      <Nav.Item
                                        as="li"
                                        onClick={() =>
                                          setCurrentMenu("API Based")
                                        }
                                      >
                                        <Nav.Link
                                          eventKey={"API Based"}
                                          as={Link}
                                          to={ROUTE_PATH.REVERSAL_API_BASED}
                                          bsPrefix={`nav-list-item ${
                                            currentMenu === "API Based"
                                              ? "subActive"
                                              : ""
                                          }`}
                                        >
                                          <div className="d-flex gap-2">
                                            <CheckListIcon />
                                            <span className="menu-name">
                                              API Based
                                            </span>
                                          </div>
                                        </Nav.Link>
                                      </Nav.Item>
                                    )}
                                  </ul>
                                </div>
                              </Collapse>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      )}
                      {(accessData.includes("Payement_Reports") ||
                        accessData.includes("Payment_Disbursement_Reports") ||
                        accessData.includes("Payment_Disbursement_Table")) && (
                        <Nav.Item as="li">
                          <Nav.Link
                            eventKey={"Payment Reports"}
                            bsPrefix={`nav-list-item ${
                              currentMenu === "Payment Reports" ||
                              currentMenu === "Payment Report" ||
                              currentMenu === "Disbursement Report" ||
                              currentMenu === "Disbursement Table"
                                ? "subActive"
                                : ""
                            }`}
                          >
                            <div className="d-grid w-100">
                              <div
                                onClick={() => {
                                  currentMenu === "Payment Reports" ||
                                  currentMenu === "Payment Report" ||
                                  currentMenu === "Disbursement Report" ||
                                  currentMenu === "Disbursement Table"
                                    ? setCurrentMenu("")
                                    : setCurrentMenu("Payment Reports");
                                }}
                                className="w-100 d-flex justify-content-between"
                              >
                                <div className="d-flex gap-2">
                                  <CheckListIcon />
                                  <span className="menu-name">Reports</span>
                                </div>
                                {currentMenu === "Payment Reports" ||
                                currentMenu === "Payment Report" ||
                                currentMenu === "Disbursement Report" ||
                                currentMenu === "Disbursement Table" ? (
                                  <UpIcon />
                                ) : (
                                  <DownIcon />
                                )}
                              </div>
                              <Collapse
                                in={
                                  currentMenu === "Payment Reports" ||
                                  currentMenu === "Payment Report" ||
                                  currentMenu === "Disbursement Report" ||
                                  currentMenu === "Disbursement Table"
                                }
                              >
                                <div>
                                  <ul className="nav-sub-menu">
                                    {accessData.includes(
                                      "Payement_Reports"
                                    ) && (
                                      <Nav.Item
                                        as="li"
                                        onClick={() =>
                                          setCurrentMenu("Payment Report")
                                        }
                                      >
                                        <Nav.Link
                                          eventKey={"Payment Report"}
                                          as={Link}
                                          to={ROUTE_PATH.Payment_Report}
                                          bsPrefix={`nav-list-item ${
                                            currentMenu === "Payment Report"
                                              ? "subActive"
                                              : ""
                                          }`}
                                        >
                                          <div className="d-flex gap-2">
                                            <CheckListIcon />
                                            <span className="menu-name">
                                              Payment Report
                                            </span>
                                          </div>
                                        </Nav.Link>
                                      </Nav.Item>
                                    )}
                                    {accessData.includes(
                                      "Payment_Disbursement_Reports"
                                    ) && (
                                      <Nav.Item
                                        as="li"
                                        onClick={() =>
                                          setCurrentMenu("Disbursement Report")
                                        }
                                      >
                                        <Nav.Link
                                          eventKey={"Disbursement Report"}
                                          as={Link}
                                          to={ROUTE_PATH.Disbursement_Report}
                                          bsPrefix={`nav-list-item ${
                                            currentMenu ===
                                            "Disbursement Report"
                                              ? "subActive"
                                              : ""
                                          }`}
                                        >
                                          <div className="d-flex gap-2">
                                            <CheckListIcon />
                                            <span className="menu-name">
                                              Disbursement Report
                                            </span>
                                          </div>
                                        </Nav.Link>
                                      </Nav.Item>
                                    )}
                                    {accessData.includes(
                                      "Payment_Disbursement_Table"
                                    ) && (
                                      <Nav.Item
                                        as="li"
                                        onClick={() =>
                                          setCurrentMenu("Disbursement Table")
                                        }
                                      >
                                        <Nav.Link
                                          eventKey={"Disbursement Table"}
                                          as={Link}
                                          to={ROUTE_PATH.Disbursement_Table}
                                          bsPrefix={`nav-list-item ${
                                            currentMenu === "Disbursement Table"
                                              ? "subActive"
                                              : ""
                                          }`}
                                        >
                                          <div className="d-flex gap-2">
                                            <CheckListIcon />
                                            <span className="menu-name">
                                              Disbursement Table
                                            </span>
                                          </div>
                                        </Nav.Link>
                                      </Nav.Item>
                                    )}
                                  </ul>
                                </div>
                              </Collapse>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      )}
                      {(accessData.includes("MM_Case_Details") ||
                        accessData.includes("Move_To_Exception") ||
                        accessData.includes("MM_Case_Detail_Approval")) && (
                        <Nav.Item as="li">
                          <Nav.Link
                            eventKey={"Manual Marking"}
                            bsPrefix={`nav-list-item ${
                              currentMenu === "Manual Marking" ||
                              currentMenu === "Case Details" ||
                              currentMenu === "CaseDetailsApproval" ||
                              currentMenu === "Move to Exception"
                                ? "subActive"
                                : ""
                            }`}
                          >
                            <div className="d-grid w-100">
                              <div
                                onClick={() => {
                                  currentMenu === "Manual Marking" ||
                                  currentMenu === "Case Details" ||
                                  currentMenu === "CaseDetailsApproval" ||
                                  currentMenu === "Move to Exception"
                                    ? setCurrentMenu("")
                                    : setCurrentMenu("Manual Marking");
                                }}
                                className="w-100 d-flex justify-content-between"
                              >
                                <div className="d-flex gap-2">
                                  <CheckListIcon />
                                  <span className="menu-name">
                                    Manual Marking
                                  </span>
                                </div>
                                {currentMenu === "Manual Marking" ||
                                currentMenu === "Case Details" ||
                                currentMenu === "CaseDetailsApproval" ||
                                currentMenu === "Move to Exception" ? (
                                  <UpIcon />
                                ) : (
                                  <DownIcon />
                                )}
                              </div>
                              <Collapse
                                in={
                                  currentMenu === "Manual Marking" ||
                                  currentMenu === "Case Details" ||
                                  currentMenu === "CaseDetailsApproval" ||
                                  currentMenu === "Move to Exception"
                                }
                              >
                                <div>
                                  <ul className="nav-sub-menu">
                                    {accessData.includes("MM_Case_Details") && (
                                      <Nav.Item
                                        as="li"
                                        onClick={() =>
                                          setCurrentMenu("Case Details")
                                        }
                                      >
                                        <Nav.Link
                                          eventKey={"Case Details"}
                                          as={Link}
                                          to={ROUTE_PATH.CaseDetails}
                                          bsPrefix={`nav-list-item ${
                                            currentMenu === "Case Details"
                                              ? "subActive"
                                              : ""
                                          }`}
                                        >
                                          <div className="d-flex gap-2">
                                            <CheckListIcon />
                                            <span className="menu-name">
                                              Case Details
                                            </span>
                                          </div>
                                        </Nav.Link>
                                      </Nav.Item>
                                    )}
                                    {accessData.includes(
                                      "Move_To_Exception"
                                    ) && (
                                      <Nav.Item
                                        as="li"
                                        onClick={() =>
                                          setCurrentMenu("Move to Exception")
                                        }
                                      >
                                        <Nav.Link
                                          eventKey={"Move to Exception"}
                                          as={Link}
                                          to={ROUTE_PATH.MoveToException}
                                          bsPrefix={`nav-list-item ${
                                            currentMenu === "Move to Exception"
                                              ? "subActive"
                                              : ""
                                          }`}
                                        >
                                          <div className="d-flex gap-2">
                                            <CheckListIcon />
                                            <span className="menu-name">
                                              Move to Exception
                                            </span>
                                          </div>
                                        </Nav.Link>
                                      </Nav.Item>
                                    )}
                                    {accessData.includes(
                                      "case_details_approval"
                                    ) && (
                                      <Nav.Item
                                        as="li"
                                        onClick={() =>
                                          setCurrentMenu("CaseDetailsApproval")
                                        }
                                      >
                                        <Nav.Link
                                          eventKey={"CaseDetailsApproval"}
                                          as={Link}
                                          to={ROUTE_PATH.CASE_DETAILS_APPROVAL}
                                          bsPrefix={`nav-list-item ${
                                            currentMenu == "CaseDetailsApproval"
                                              ? "subActive"
                                              : ""
                                          }`}
                                        >
                                          <div className="d-flex gap-2">
                                            <CheckListIcon />
                                            <span className="menu-name">
                                              Case Details Approval
                                            </span>
                                          </div>
                                        </Nav.Link>
                                      </Nav.Item>
                                    )}
                                  </ul>
                                </div>
                              </Collapse>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      )}
                    </ul>
                  </div>
                </Collapse>
              </div>
            </Nav.Link>
          </Nav.Item>
        )}
                {(accessData.includes("AutoPay")||
                  accessData.includes("AutoPayBankMaster")) && (
          <Nav.Item as="li">
            <Nav.Link eventKey={"Auto Payment"} bsPrefix="nav-list-item">
              <div className="d-grid w-100">
                <div
                  onClick={() => {
                    closeRemainingTabs("autopayment");
                    setCurrentMenu("AutoPayment");
                  }}
                  className="w-100 d-flex justify-content-between"
                >
                  <div className="d-flex gap-2">
                    <WalletIcon />
                    <span className="menu-name">Auto Payment</span>
                  </div>
                  {autoPaymentMenu ? <UpIcon /> : <DownIcon />}
                </div>
                <Collapse in={autoPaymentMenu}>
                  <div>
                    <ul className="nav-sub-menu">
                    {accessData.includes("AutoPay") && (
                        <Nav.Item as="li" onClick={() => setCurrentMenu("Auto Regular Payment")}>
                          <Nav.Link
                            eventKey={"Auto Regular Payment"}
                            as={Link}
                            to={ROUTE_PATH.REGULAR_AUTO_PAYMENT}
                            bsPrefix={`nav-list-item ${
                              currentMenu === "Auto Regular Payment" ? "subActive" : ""
                            }`}>
                            <div className="d-flex gap-2">
                              <CheckListIcon />
                              <span className="menu-name">Auto Regular Payment</span>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                        )}
                        {accessData.includes("AutoPayBankMaster") && (
                        <Nav.Item as="li" onClick={() => setCurrentMenu("Auto Payment Master")}>
                          <Nav.Link
                            eventKey={"Auto Payment Master"}
                            as={Link}
                            to={ROUTE_PATH.AUTO_PAYMENT_BANK_MASTEER}
                            bsPrefix={`nav-list-item ${
                              currentMenu === "Auto Payment Master" ? "subActive" : ""
                            }`}>
                            <div className="d-flex gap-2">
                              <CheckListIcon />
                              <span className="menu-name">Auto Payment Bank Master</span>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                        )}
                    </ul>
                  </div>
                </Collapse>
              </div>
            </Nav.Link>
          </Nav.Item>
        )}
          {(accessData.includes("chequeMatser")) && (
          <Nav.Item as="li">
            <Nav.Link eventKey={"Cheque Master"} bsPrefix="nav-list-item">
              <div className="d-grid w-100">
                <div
                  onClick={() => {
                    closeRemainingTabs("ChequeMaster");
                    setCurrentMenu("Cheque Master");
                  }}
                  className="w-100 d-flex justify-content-between"
                >
                  <div className="d-flex gap-2">
                    <WalletIcon />
                    <span className="menu-name">Cheque Master</span>
                  </div>
                  {chequeMatser ? <UpIcon /> : <DownIcon />}
                </div>
                <Collapse in={chequeMatser}>
                  <div>
                    <ul className="nav-sub-menu">
                      <Nav.Item
                        as="li"
                        onClick={() => setCurrentMenu("Cheque Maker")}
                      >
                        <Nav.Link
                          eventKey={"Cheque Maker"}
                          as={Link}
                          to={ROUTE_PATH.CHEQUE_MAKER}
                          bsPrefix={`nav-list-item ${
                            currentMenu === "Cheque Maker" ? "subActive" : ""
                          }`}
                        >
                          <div className="d-flex gap-2">
                            <CheckListIcon />
                            <span className="menu-name">Cheque Maker</span>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item
                        as="li"
                        onClick={() => setCurrentMenu("Cheque Checker")}
                      >
                        <Nav.Link
                          eventKey={"Cheque Checker"}
                          as={Link}
                          to={ROUTE_PATH.CHEQUE_CHECKER}
                          bsPrefix={`nav-list-item ${
                            currentMenu === "Cheque Checker" ? "subActive" : ""
                          }`}
                        >
                          <div className="d-flex gap-2">
                            <CheckListIcon />
                            <span className="menu-name">Cheque Checker</span>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                    </ul>
                  </div>
                </Collapse>
              </div>
            </Nav.Link>
          </Nav.Item>
        )}
        {(accessData.includes("Exception_Partner_Payment") ||
          accessData.includes("Callback_Partner_Payment")) && (
          <Nav.Item as="li">
            <Nav.Link
              eventKey={"Partner Payment Master"}
              bsPrefix="nav-list-item"
            >
              <div className="d-grid w-100">
                <div
                  onClick={() => {
                    closeRemainingTabs("partnerpayments");
                    setCurrentMenu("Partner Payments");
                  }}
                  className="w-100 d-flex justify-content-between"
                >
                  <div className="d-flex gap-2">
                    <WalletIcon />
                    <span className="menu-name">Partner Payments</span>
                  </div>
                  {partnerpaymentMenu ? <UpIcon /> : <DownIcon />}
                </div>
                <Collapse in={partnerpaymentMenu}>
                  <div>
                    <ul className="nav-sub-menu">
                      {accessData.includes("Exception_Partner_Payment") && (
                        <Nav.Item
                          as="li"
                          onClick={() => setCurrentMenu("Exception")}
                        >
                          <Nav.Link
                            eventKey={"Exception"}
                            as={Link}
                            to={ROUTE_PATH.EXCEPTION}
                            bsPrefix={`nav-list-item ${
                              currentMenu === "Exception" ? "subActive" : ""
                            }`}
                          >
                            <div className="d-flex gap-2">
                              <CheckListIcon />
                              <span className="menu-name">Exception</span>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      )}
                      {accessData.includes("Callback_Partner_Payment") && (
                        <Nav.Item
                          as="li"
                          onClick={() => setCurrentMenu("Callback")}
                        >
                          <Nav.Link
                            eventKey={"Callback"}
                            as={Link}
                            to={ROUTE_PATH.CALLBACK}
                            bsPrefix={`nav-list-item ${
                              currentMenu === "Callback" ? "subActive" : ""
                            }`}
                          >
                            <div className="d-flex gap-2">
                              <CheckListIcon />
                              <span className="menu-name">Callback</span>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      )}
                    </ul>
                  </div>
                </Collapse>
              </div>
            </Nav.Link>
          </Nav.Item>
        )}
        {/* {(accessData.includes("Regular_Payment")) ||
          accessData.includes('MM_Case_Detail_Approval') && (
          <Nav.Item as ="li">
            <Nav.Link eventKey={"Partner Payments"} bsPrefix="nav-list-item">
              <div className="d-grid w-100">
                <div
                onClick={() => {
                  closeRemainingTabs("partnerpayments")
                  setCurrentMenu("Partner Payments")
                }}
                className="w-100 d-flex justify-content-between">
                   <div className="d-flex gap-2">
                    <WalletIcon />
                    <span className="menu-name">Partner Payments</span>
                  </div>
                  {partnerpaymentMenu ? <UpIcon /> : <DownIcon/>}
                  </div>
                  <Collapse in={partnerpaymentMenu}>
                  <div>
                    <ul className="nav-sub-menu">
                      {accessData.includes("Exception") && (
                        <Nav.Item as="li" onClick={() => setCurrentMenu("Exception")}>
                          <Nav.Link
                            eventKey={"Exception"}
                            as={Link}
                            to={ROUTE_PATH.EXCEPTION}
                            bsPrefix={`nav-list-item ${
                              currentMenu === "Exception" ? "subActive" : ""
                            }`}>
                            <div className="d-flex gap-2">
                              <CheckListIcon />
                              <span className="menu-name">Exception</span>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      )}
                      {accessData.includes("Callback") && (
                        <Nav.Item as="li" onClick={() => setCurrentMenu("Callback")}>
                          <Nav.Link
                            eventKey={"Callback"}
                            as={Link}
                            to={ROUTE_PATH.CALLBACK}
                            bsPrefix={`nav-list-item ${
                              currentMenu === "Callback" ? "subActive" : ""
                            }`}>
                            <div className="d-flex gap-2">
                              <CheckListIcon />
                              <span className="menu-name">Callback</span>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      )}
                    </ul>
                  </div>
                  </Collapse>
              </div>
              </Nav.Link>
          </Nav.Item>
        )} */}
        {(accessData.includes("USER") ||
          accessData.includes("User_Masters")) && (
          <Nav.Item as="li">
            <Nav.Link eventKey={"User Master"} bsPrefix="nav-list-item">
              <div className="d-grid w-100">
                <div
                  onClick={() => {
                    closeRemainingTabs("users");
                    setCurrentMenu("User Master");
                  }}
                  className="w-100 d-flex justify-content-between"
                >
                  <div className="d-flex gap-2">
                    <PayableIcon />
                    <span className="menu-name">User Master</span>
                  </div>
                  {masters ? <UpIcon /> : <DownIcon />}
                </div>
                <Collapse in={masters}>
                  <div>
                    <ul className="nav-sub-menu">
                      {accessData.includes("USER") && (
                        <Nav.Item
                          as="li"
                          onClick={() => setCurrentMenu("User")}
                        >
                          <Nav.Link
                            eventKey={"User"}
                            as={Link}
                            to={ROUTE_PATH.USER}
                            bsPrefix={`nav-list-item ${currentMenu === "User" ? "subActive" : ""}`}
                          >
                            <div className="d-flex gap-2">
                              <CheckListIcon />
                              <span className="menu-name">User</span>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      )}
                      {accessData.includes("User_Masters") && (
                        <Nav.Item
                          as="li"
                          onClick={() => setCurrentMenu("Role")}
                        >
                          <Nav.Link
                            eventKey={"Role"}
                            as={Link}
                            to={ROUTE_PATH.ROLE}
                            bsPrefix={`nav-list-item ${currentMenu === "Role" ? "subActive" : ""}`}
                          >
                            <div className="d-flex gap-2">
                              <CheckListIcon />
                              <span className="menu-name">Role</span>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      )}
                      {accessData.includes("User_Masters") && (
                        <Nav.Item
                          as="li"
                          onClick={() => setCurrentMenu("User Type")}
                        >
                          <Nav.Link
                            eventKey={"User Type"}
                            as={Link}
                            to={ROUTE_PATH.USERTYPE}
                            bsPrefix={`nav-list-item ${
                              currentMenu === "User Type" ? "subActive" : ""
                            }`}
                          >
                            <div className="d-flex gap-2">
                              <CheckListIcon />
                              <span className="menu-name">User Type</span>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      )}
                      {accessData.includes("User_Masters") && (
                        <Nav.Item
                          as="li"
                          onClick={() =>
                            setCurrentMenu("API User Registration")
                          }
                        >
                          <Nav.Link
                            eventKey={"API User Registration"}
                            as={Link}
                            to={ROUTE_PATH.API_USER_REGISTRATION}
                            bsPrefix={`nav-list-item ${
                              currentMenu === "API User Registration"
                                ? "subActive"
                                : ""
                            }`}
                          >
                            <div className="d-flex gap-2">
                              <CheckListIcon />
                              <span className="menu-name">
                                API User Registration
                              </span>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      )}
                    </ul>
                  </div>
                </Collapse>
              </div>
            </Nav.Link>
          </Nav.Item>
        )}
        {(accessData.includes("Scheme_Master") ||
          accessData.includes("System_Master")) && (
          <Nav.Item as="li">
            <Nav.Link eventKey={"System Master1"} bsPrefix="nav-list-item">
              <div className="d-grid w-100">
                <div
                  onClick={() => {
                    closeRemainingTabs("systemMasters");
                    setCurrentMenu("System Master");
                  }}
                  className="w-100 d-flex justify-content-between"
                >
                  <div className="d-flex gap-2">
                    <PayableIcon />
                    <span className="menu-name">System Master</span>
                  </div>
                  {systemMaster ? <UpIcon /> : <DownIcon />}
                </div>
                <Collapse in={systemMaster}>
                  <div>
                    <ul className="nav-sub-menu">
                      {accessData.includes("System_Master") && (
                        <Nav.Item
                          as="li"
                          onClick={() => setCurrentMenu("Company Master")}
                        >
                          <Nav.Link
                            eventKey={"Company Master"}
                            as={Link}
                            to={ROUTE_PATH.COMPANY}
                            bsPrefix={`nav-list-item ${
                              currentMenu === "Company Master"
                                ? "subActive"
                                : ""
                            }`}
                          >
                            <div className="d-flex gap-2">
                              <CheckListIcon />
                              <span className="menu-name">Company</span>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      )}
                      {accessData.includes("System_Master") && (
                        <Nav.Item
                          as="li"
                          onClick={() => setCurrentMenu("Mandate Type")}
                        >
                          <Nav.Link
                            eventKey={"Mandate Type"}
                            as={Link}
                            to={ROUTE_PATH.E_MANDATE_TYPE}
                            bsPrefix={`nav-list-item ${
                              currentMenu === "Mandate Type" ? "subActive" : ""
                            }`}
                          >
                            <div className="d-flex gap-2">
                              <CheckListIcon />
                              <span className="menu-name">Mandate Type</span>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      )}
                      {accessData.includes("System_Master") && (
                        <Nav.Item
                          as="li"
                          onClick={() => setCurrentMenu("Frequency")}
                        >
                          <Nav.Link
                            eventKey={"Frequency"}
                            as={Link}
                            to={ROUTE_PATH.FREQUENCY}
                            bsPrefix={`nav-list-item ${
                              currentMenu === "Frequency" ? "subActive" : ""
                            }`}
                          >
                            <div className="d-flex gap-2">
                              <CheckListIcon />
                              <span className="menu-name">Frequency</span>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      )}
                      {accessData.includes("System_Master") && (
                        <Nav.Item
                          as="li"
                          onClick={() => setCurrentMenu("System")}
                        >
                          <Nav.Link
                            eventKey={"System"}
                            as={Link}
                            to={ROUTE_PATH.SYSTEM}
                            bsPrefix={`nav-list-item ${
                              currentMenu === "System" ? "subActive" : ""
                            }`}
                          >
                            <div className="d-flex gap-2">
                              <CheckListIcon />
                              <span className="menu-name">System</span>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      )}
                      {accessData.includes("System_Master") && (
                        <Nav.Item
                          as="li"
                          onClick={() => setCurrentMenu("Source System")}
                        >
                          <Nav.Link
                            eventKey={"Source System"}
                            as={Link}
                            to={ROUTE_PATH.SOURCE_SYSTEM_MASTER}
                            bsPrefix={`nav-list-item ${
                              currentMenu === "Source System" ? "subActive" : ""
                            }`}
                          >
                            <div className="d-flex gap-2">
                              <CheckListIcon />
                              <span className="menu-name">Source System</span>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      )}
                      {accessData.includes("System_Master") && (
                        <Nav.Item
                          as="li"
                          onClick={() => setCurrentMenu("Enach Vendor")}
                        >
                          <Nav.Link
                            eventKey={"Enach Vendor"}
                            as={Link}
                            to={ROUTE_PATH.ENACH_VENDER}
                            bsPrefix={`nav-list-item ${
                              currentMenu === "Enach Vendor" ? "subActive" : ""
                            }`}
                          >
                            <div className="d-flex gap-2">
                              <CheckListIcon />
                              <span className="menu-name">Enach Vendor</span>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      )}
                      {accessData.includes("System_Master") && (
                        <Nav.Item
                          as="li"
                          onClick={() => setCurrentMenu("Account Type")}
                        >
                          <Nav.Link
                            eventKey={"Account Type"}
                            as={Link}
                            to={ROUTE_PATH.ACCOUNT_TYPE}
                            bsPrefix={`nav-list-item ${
                              currentMenu === "Account Type" ? "subActive" : ""
                            }`}
                          >
                            <div className="d-flex gap-2">
                              <CheckListIcon />
                              <span className="menu-name">Account Type</span>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      )}
                      {accessData.includes("System_Master") && (
                        <Nav.Item as="li">
                          <Nav.Link
                            eventKey={"Banks Sponsor"}
                            bsPrefix={`nav-list-item ${
                              currentMenu === "Banks Sponsor" ? "subActive" : ""
                            }`}
                          >
                            <div className="d-grid w-100">
                              <div
                                onClick={() => {
                                  currentMenu === "Banks Sponsor"
                                    ? setCurrentMenu("")
                                    : setCurrentMenu("Banks Sponsor");
                                }}
                                className="w-100 d-flex justify-content-between"
                              >
                                <div className="d-flex gap-2">
                                  <CheckListIcon />
                                  <span className="menu-name">
                                    Sponsor Bank
                                  </span>
                                </div>
                                {currentMenu === "Banks Sponsor" ? (
                                  <UpIcon />
                                ) : (
                                  <DownIcon />
                                )}
                              </div>
                              <Collapse
                                in={
                                  currentMenu === "Banks Sponsor" ||
                                  currentMenu === "PaymentSponsorBanks" ||
                                  currentMenu === "Sponsor Payment Bank" ||
                                  currentMenu === "Collection Banks Sponsor" ||
                                  currentMenu === "BaNCS Banks Sponsor" ||
                                  currentMenu === "Nupay Bank Report"
                                }
                              >
                                <div>
                                  <ul className="nav-sub-menu">
                                    {accessData.includes("System_Master") && (
                                      <Nav.Item
                                        as="li"
                                        onClick={() =>
                                          setCurrentMenu(
                                            "Collection Banks Sponsor"
                                          )
                                        }
                                      >
                                        <Nav.Link
                                          eventKey={"Collection Banks Sponsor"}
                                          as={Link}
                                          to={
                                            ROUTE_PATH.COLLECTION_SPONSOR_BANK
                                          }
                                          bsPrefix={`nav-list-item ${
                                            currentMenu ===
                                            "Collection Banks Sponsor"
                                              ? "subActive"
                                              : ""
                                          }`}
                                        >
                                          <div className="d-flex gap-2">
                                            <CheckListIcon />
                                            <span className="menu-name">
                                              Collection Banks Sponsor
                                            </span>
                                          </div>
                                        </Nav.Link>
                                      </Nav.Item>
                                    )}
                                    {/* {accessData.includes("System_Master") && (
                                      <Nav.Item
                                        as="li"
                                        onClick={() => setCurrentMenu("BaNCS Banks Sponsor")}>
                                        <Nav.Link
                                          eventKey={"BaNCS Banks Sponsor"}
                                          as={Link}
                                          to={ROUTE_PATH.BANCS_SPONSOR_BANK}
                                          bsPrefix={`nav-list-item ${
                                            currentMenu === "BaNCS Banks Sponsor" ? "subActive" : ""
                                          }`}>
                                          <div className="d-flex gap-2">
                                            <CheckListIcon />
                                            <span className="menu-name">BaNCS Banks Sponsor</span>
                                          </div>
                                        </Nav.Link>
                                      </Nav.Item>
                                    )} */}
                                    {accessData.includes("System_Master") && (
                                      <Nav.Item as="li">
                                        <Nav.Link
                                          eventKey={"PaymentSponsorBanks"}
                                          bsPrefix={`nav-list-item ${
                                            currentMenu ===
                                              "PaymentSponsorBanks" ||
                                            currentMenu ===
                                              "Sponsor Payment Bank" ||
                                            currentMenu === "INDUSIND Report"
                                              ? "subActive"
                                              : ""
                                          }`}
                                        >
                                          <div className="d-grid w-100">
                                            <div
                                              onClick={() => {
                                                currentMenu ===
                                                  "PaymentSponsorBanks" ||
                                                currentMenu ===
                                                  "Sponsor Payment Bank" ||
                                                currentMenu ===
                                                  "INDUSIND Report"
                                                  ? setCurrentMenu(
                                                      "Banks Sponsor"
                                                    )
                                                  : setCurrentMenu(
                                                      "PaymentSponsorBanks"
                                                    );
                                              }}
                                              className="w-100 d-flex justify-content-between"
                                            >
                                              <div className="d-flex gap-2">
                                                <CheckListIcon />
                                                <span className="menu-name">
                                                  Sponsor Bank Payment
                                                </span>
                                              </div>
                                              {currentMenu ===
                                                "PaymentSponsorBanks" ||
                                              currentMenu ===
                                                "Sponsor Payment Bank" ? (
                                                <UpIcon />
                                              ) : (
                                                <DownIcon />
                                              )}
                                            </div>
                                            <Collapse
                                              in={
                                                currentMenu ===
                                                "PaymentSponsorBanks"
                                              }
                                            >
                                              <div>
                                                <ul className="nav-sub-menu">
                                                  <Nav.Item
                                                    as="li"
                                                    onClick={() =>
                                                      currentMenu ===
                                                        "PaymentSponsorBanks" ||
                                                      currentMenu ===
                                                        "Payment Type" ||
                                                      currentMenu ===
                                                        "Sponsor Payment Bank" ||
                                                      currentMenu ===
                                                        "Business ID"
                                                        ? setCurrentMenu(
                                                            "PaymentSponsorBanks"
                                                          )
                                                        : setCurrentMenu(
                                                            "Payment Type"
                                                          )
                                                    }
                                                  >
                                                    <Nav.Link
                                                      eventKey={"Payment Type"}
                                                      as={Link}
                                                      to={
                                                        ROUTE_PATH.PAYMENT_TYPE
                                                      }
                                                      bsPrefix={`nav-list-item ${
                                                        currentMenu ===
                                                        "Payment Type"
                                                          ? "subActive"
                                                          : ""
                                                      }`}
                                                    >
                                                      <div className="d-flex gap-2">
                                                        <CheckListIcon />
                                                        <span className="menu-name">
                                                          Payment Type{" "}
                                                        </span>
                                                      </div>
                                                    </Nav.Link>
                                                  </Nav.Item>
                                                  <Nav.Item
                                                    as="li"
                                                    onClick={() =>
                                                      currentMenu ===
                                                        "PaymentSponsorBanks" ||
                                                      currentMenu ===
                                                        "Payment Type" ||
                                                      currentMenu ===
                                                        "Sponsor Payment Bank" ||
                                                      currentMenu ===
                                                        "Business ID"
                                                        ? setCurrentMenu(
                                                            "PaymentSponsorBanks"
                                                          )
                                                        : setCurrentMenu(
                                                            "Sponsor Payment Bank"
                                                          )
                                                    }
                                                  >
                                                    <Nav.Link
                                                      eventKey={
                                                        "Sponsor Payment Bank"
                                                      }
                                                      as={Link}
                                                      to={
                                                        ROUTE_PATH.SPONSOR_PAYMENT_BANK
                                                      }
                                                      bsPrefix={`nav-list-item ${
                                                        currentMenu ===
                                                        "Sponsor Payment Bank"
                                                          ? "subActive"
                                                          : ""
                                                      }`}
                                                    >
                                                      <div className="d-flex gap-2">
                                                        <CheckListIcon />
                                                        <span className="menu-name">
                                                          Sponsor Payment Bank{" "}
                                                        </span>
                                                      </div>
                                                    </Nav.Link>
                                                  </Nav.Item>
                                                  <Nav.Item
                                                    as="li"
                                                    onClick={() =>
                                                      currentMenu ===
                                                        "PaymentSponsorBanks" ||
                                                      currentMenu ===
                                                        "Payment Type" ||
                                                      currentMenu ===
                                                        "Sponsor Payment Bank" ||
                                                      currentMenu ===
                                                        "Business ID" ||
                                                      currentMenu ===
                                                        "Recipt Type"
                                                        ? setCurrentMenu(
                                                            "PaymentSponsorBanks"
                                                          )
                                                        : setCurrentMenu(
                                                            "Business ID"
                                                          )
                                                    }
                                                  >
                                                    <Nav.Link
                                                      eventKey={"Business ID"}
                                                      as={Link}
                                                      to={
                                                        ROUTE_PATH.BUSINESS_ID
                                                      }
                                                      bsPrefix={`nav-list-item ${
                                                        currentMenu ===
                                                        "Business ID"
                                                          ? "subActive"
                                                          : ""
                                                      }`}
                                                    >
                                                      <div className="d-flex gap-2">
                                                        <CheckListIcon />
                                                        <span className="menu-name">
                                                          Business ID
                                                        </span>
                                                      </div>
                                                    </Nav.Link>
                                                  </Nav.Item>
                                                  <Nav.Item
                                                    as="li"
                                                    onClick={() =>
                                                      currentMenu ===
                                                        "PaymentSponsorBanks" ||
                                                      currentMenu ===
                                                        "Payment Type" ||
                                                      currentMenu ===
                                                        "Sponsor Payment Bank" ||
                                                      currentMenu ===
                                                        "Business ID" ||
                                                      currentMenu ===
                                                        "Recipt Type"
                                                        ? setCurrentMenu(
                                                            "PaymentSponsorBanks"
                                                          )
                                                        : setCurrentMenu(
                                                            "Recipt Type"
                                                          )
                                                    }
                                                  >
                                                    <Nav.Link
                                                      eventKey={"Recipt Type"}
                                                      as={Link}
                                                      to={
                                                        ROUTE_PATH.RECEIPT_TYPE
                                                      }
                                                      bsPrefix={`nav-list-item ${
                                                        currentMenu ===
                                                        "Recipt Type"
                                                          ? "subActive"
                                                          : ""
                                                      }`}
                                                    >
                                                      <div className="d-flex gap-2">
                                                        <CheckListIcon />
                                                        <span className="menu-name">
                                                          Receipt Type
                                                        </span>
                                                      </div>
                                                    </Nav.Link>
                                                  </Nav.Item>
                                                </ul>
                                              </div>
                                            </Collapse>
                                          </div>
                                        </Nav.Link>
                                      </Nav.Item>
                                    )}
                                    {accessData.includes("System_Master") && (
                                      <Nav.Item
                                        as="li"
                                        onClick={() =>
                                          setCurrentMenu("Nupay Bank Report")
                                        }
                                      >
                                        <Nav.Link
                                          eventKey={"Nupay Bank Report"}
                                          as={Link}
                                          to={ROUTE_PATH.NUPAY_BANK_REPORTS}
                                          bsPrefix={`nav-list-item ${
                                            currentMenu === "Nupay Bank Report"
                                              ? "subActive"
                                              : ""
                                          }`}
                                        >
                                          <div className="d-flex gap-2">
                                            <CheckListIcon />
                                            <span className="menu-name">
                                              Nupay Bank Report
                                            </span>
                                          </div>
                                        </Nav.Link>
                                      </Nav.Item>
                                    )}
                                  </ul>
                                </div>
                              </Collapse>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      )}
                      {accessData.includes("System_Master") && (
                        <Nav.Item
                          as="li"
                          onClick={() => setCurrentMenu("Vendor")}
                        >
                          <Nav.Link
                            eventKey={"Vendor"}
                            as={Link}
                            to={ROUTE_PATH.VENDOR}
                            bsPrefix={`nav-list-item ${
                              currentMenu === "Vendor" ? "subActive" : ""
                            }`}
                          >
                            <div className="d-flex gap-2">
                              <CheckListIcon />
                              <span className="menu-name">Vendor</span>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      )}
                      {accessData.includes("System_Master") && (
                        <Nav.Item
                          as="li"
                          onClick={() => setCurrentMenu("RPT Rejection")}
                        >
                          <Nav.Link
                            eventKey={"RPT Rejection"}
                            as={Link}
                            to={ROUTE_PATH.REJECTION}
                            bsPrefix={`nav-list-item ${
                              currentMenu === "RPT Rejection" ? "subActive" : ""
                            }`}
                          >
                            <div className="d-flex gap-2">
                              <CheckListIcon />
                              <span className="menu-name">RPT Rejection</span>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      )}
                      {accessData.includes("Scheme_Master") && (
                        <Nav.Item
                          as="li"
                          onClick={() => setCurrentMenu("Scheme Master")}
                        >
                          <Nav.Link
                            eventKey={"Scheme Master"}
                            as={Link}
                            to={ROUTE_PATH.SCHEME}
                            bsPrefix={`nav-list-item ${
                              currentMenu === "Scheme Master" ? "subActive" : ""
                            }`}
                          >
                            <div className="d-flex gap-2">
                              <CheckListIcon />
                              <span className="menu-name">Scheme Master</span>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      )}
                    </ul>
                  </div>
                </Collapse>
              </div>
            </Nav.Link>
          </Nav.Item>
        )}
        {accessData.includes("System_Master_2") && (
          <Nav.Item as="li">
            <Nav.Link eventKey={"System Master2"} bsPrefix="nav-list-item">
              <div className="d-grid w-100">
                <div
                  onClick={() => {
                    closeRemainingTabs("systemMasters2");
                    setCurrentMenu("System Master2");
                  }}
                  className="w-100 d-flex justify-content-between"
                >
                  <div className="d-flex gap-2">
                    <PayableIcon />
                    <span className="menu-name">System Master 2</span>
                  </div>
                  {systemMaster2 ? <UpIcon /> : <DownIcon />}
                </div>
                <Collapse in={systemMaster2}>
                  <div>
                    <ul className="nav-sub-menu">
                      {accessData.includes("System_Master_2") && (
                        <Nav.Item
                          as="li"
                          onClick={() => setCurrentMenu("HealthCheckRejection")}
                        >
                          <Nav.Link
                            eventKey={"HealthCheckRejection"}
                            as={Link}
                            to={ROUTE_PATH.HEALTH_REJECTION}
                            bsPrefix={`nav-list-item ${
                              currentMenu === "HealthCheckRejection"
                                ? "subActive"
                                : ""
                            }`}
                          >
                            <div className="d-flex gap-2">
                              <CheckListIcon />
                              <span className="menu-name">
                                Health Check Rejection
                              </span>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      )}
                      {accessData.includes("System_Master_2") && (
                        <Nav.Item
                          as="li"
                          onClick={() =>
                            setCurrentMenu("Registration Rejection")
                          }
                        >
                          <Nav.Link
                            eventKey={"Registration Rejection"}
                            as={Link}
                            to={ROUTE_PATH.REGISTRATION_REJECTION}
                            bsPrefix={`nav-list-item ${
                              currentMenu === "Registration Rejection"
                                ? "subActive"
                                : ""
                            }`}
                          >
                            <div className="d-flex gap-2">
                              <CheckListIcon />
                              <span className="menu-name">
                                Registration Rejection
                              </span>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      )}
                      {accessData.includes("System_Master_2") && (
                        <Nav.Item
                          as="li"
                          onClick={() =>
                            setCurrentMenu("Transaction Rejection")
                          }
                        >
                          <Nav.Link
                            eventKey={"Transaction Rejection"}
                            as={Link}
                            to={ROUTE_PATH.TRANSACTION_REJECTION}
                            bsPrefix={`nav-list-item ${
                              currentMenu === "Transaction Rejection"
                                ? "subActive"
                                : ""
                            }`}
                          >
                            <div className="d-flex gap-2">
                              <CheckListIcon />
                              <span className="menu-name">
                                Transaction Rejection
                              </span>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      )}
                      {accessData.includes("System_Master_2") && (
                        <Nav.Item
                          as="li"
                          onClick={() => setCurrentMenu("Category")}
                        >
                          <Nav.Link
                            eventKey={"Category"}
                            as={Link}
                            to={ROUTE_PATH.CATEGORY}
                            bsPrefix={`nav-list-item ${
                              currentMenu === "Category" ? "subActive" : ""
                            }`}
                          >
                            <div className="d-flex gap-2">
                              <CheckListIcon />
                              <span className="menu-name">Category</span>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      )}
                      {accessData.includes("System_Master_2") && (
                        <Nav.Item
                          as="li"
                          onClick={() => setCurrentMenu("Product")}
                        >
                          <Nav.Link
                            eventKey={"Product"}
                            as={Link}
                            to={ROUTE_PATH.PRODUCT}
                            bsPrefix={`nav-list-item ${
                              currentMenu === "Product" ? "subActive" : ""
                            }`}
                          >
                            <div className="d-flex gap-2">
                              <CheckListIcon />
                              <span className="menu-name">Product</span>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      )}
                      {accessData.includes("System_Master_2") && (
                        <Nav.Item
                          as="li"
                          onClick={() => setCurrentMenu("Micr")}
                        >
                          <Nav.Link
                            eventKey={"Micr"}
                            as={Link}
                            to={ROUTE_PATH.MICR}
                            bsPrefix={`nav-list-item ${currentMenu === "Micr" ? "subActive" : ""}`}
                          >
                            <div className="d-flex gap-2">
                              <CheckListIcon />
                              <span className="menu-name">Micr</span>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      )}
                      {accessData.includes("System_Master_2") && (
                        <Nav.Item
                          as="li"
                          onClick={() => setCurrentMenu("Enach Months")}
                        >
                          <Nav.Link
                            eventKey={"Enach Months"}
                            as={Link}
                            to={ROUTE_PATH.ENACH_MONTHS}
                            bsPrefix={`nav-list-item ${
                              currentMenu === "Enach Months" ? "subActive" : ""
                            }`}
                          >
                            <div className="d-flex gap-2">
                              <CheckListIcon />
                              <span className="menu-name">Enach Months</span>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      )}
                      {accessData.includes("System_Master_2") && (
                        <Nav.Item
                          as="li"
                          onClick={() =>
                            setCurrentMenu("Account_validation_vendor")
                          }
                        >
                          <Nav.Link
                            eventKey={"Account_validation_vendor"}
                            as={Link}
                            to={ROUTE_PATH.ACCOUNT_VALIDATION_VENDOR}
                            bsPrefix={`nav-list-item ${
                              currentMenu === "Account_validation_vendor"
                                ? "subActive"
                                : ""
                            }`}
                          >
                            <div className="d-flex gap-2">
                              <CheckListIcon />
                              <span className="menu-name">
                                Account Validation Vendor
                              </span>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      )}
                      {accessData.includes("System_Master_2") && (
                        <Nav.Item
                          as="li"
                          onClick={() => setCurrentMenu("Disbursment_vendor")}
                        >
                          <Nav.Link
                            eventKey={"Disbursment_vendor"}
                            as={Link}
                            to={ROUTE_PATH.DISBURSMENT_VENDOR}
                            bsPrefix={`nav-list-item ${
                              currentMenu === "Disbursment_vendor"
                                ? "subActive"
                                : ""
                            }`}
                          >
                            <div className="d-flex gap-2">
                              <CheckListIcon />
                              <span className="menu-name">
                                Disbursment Vendor
                              </span>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      )}
                      {accessData.includes("System_Master_2") && (
                        <Nav.Item
                          as="li"
                          onClick={() => setCurrentMenu("Authentication_mode")}
                        >
                          <Nav.Link
                            eventKey={"Authentication_mode"}
                            as={Link}
                            to={ROUTE_PATH.AUTHENTICATION_MODE}
                            bsPrefix={`nav-list-item ${
                              currentMenu === "Authentication_mode"
                                ? "subActive"
                                : ""
                            }`}
                          >
                            <div className="d-flex gap-2">
                              <CheckListIcon />
                              <span className="menu-name">
                                Enach Authentication Mode
                              </span>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      )}
                    </ul>
                  </div>
                </Collapse>
              </div>
            </Nav.Link>
          </Nav.Item>
        )}
        {(accessData.includes("Provision_Report") ||
          accessData.includes("Provision_Analysis_Report") ||
          accessData.includes("OM_DOB_Update") ||
          accessData.includes("Mandate_Reg_Reversal_File_HDFC") ||
          accessData.includes("Mandate_Reg_Reversal_File_INDUS") ||
          accessData.includes("Scan_Merge_status") ||
          accessData.includes("Scan_SFTP_Merge_Status") ||
          accessData.includes("Payment_Logs)")) && (
          <Nav.Item as="li">
            <Nav.Link eventKey={"Reports Master"} bsPrefix="nav-list-item">
              <div className="d-grid w-100">
                <div
                  onClick={() => {
                    closeRemainingTabs("reports");
                    setCurrentMenu("Reports");
                  }}
                  className="w-100 d-flex justify-content-between"
                >
                  <div className="d-flex gap-2">
                    <PayableIcon />
                    <span className="menu-name">Reports</span>
                  </div>
                  {reports ? <UpIcon /> : <DownIcon />}
                </div>
                <Collapse in={reports}>
                  <div>
                    <ul className="nav-sub-menu">
                      {accessData.includes("Provision_Report") && (
                        <Nav.Item
                          as="li"
                          onClick={() => setCurrentMenu("Provision Reports")}
                        >
                          <Nav.Link
                            eventKey={"Provision Reports"}
                            as={Link}
                            to={ROUTE_PATH.PROVISION_REPORTS}
                            bsPrefix={`nav-list-item ${
                              currentMenu === "Provision Reports"
                                ? "subActive"
                                : ""
                            }`}
                          >
                            <div className="d-flex gap-2">
                              <CheckListIcon />
                              <span className="menu-name">
                                Provision Report
                              </span>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      )}

                      {accessData.includes("Provision_Analysis_Report") && (
                        <Nav.Item
                          as="li"
                          onClick={() =>
                            setCurrentMenu("Provision Report Analysis")
                          }
                        >
                          <Nav.Link
                            eventKey={"Provision Report Analysis"}
                            as={Link}
                            to={ROUTE_PATH.PROVISION_REPORT_ANALYSIS}
                            bsPrefix={`nav-list-item ${
                              currentMenu === "Provision Report Analysis"
                                ? "subActive"
                                : ""
                            }`}
                          >
                            <div className="d-flex gap-2">
                              <CheckListIcon />
                              <span className="menu-name">
                                Provision Report Analysis
                              </span>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      )}

                      {accessData.includes("OM_DOB_Update") && (  
                        <Nav.Item
                          as="li"
                          onClick={() => setCurrentMenu("Mandate Update")}
                        >
                          <Nav.Link
                            eventKey={"Mandate Update"}
                            as={Link}
                            to={ROUTE_PATH.MANDATE_UPDATE}
                            bsPrefix={`nav-list-item ${
                              currentMenu === "Mandate Update"
                                ? "subActive"
                                : ""
                            }`}
                          >
                            <div className="d-flex gap-2">
                              <CheckListIcon />
                              <span className="menu-name">
                                Open Mandate DOB Update
                              </span>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      )}

                      {accessData.includes(
                        "Mandate_Reg_Reversal_File_HDFC" ||
                          "Mandate_Reg_Reversal_File_INDUS"
                      ) && (
                        <Nav.Item as="li">
                          <Nav.Link
                            eventKey={"Mandate Reg. Revarsal SFTP"}
                            bsPrefix={`nav-list-item ${
                              currentMenu === "Mandate Reg. Revarsal SFTP" ||
                              currentMenu === "HDFC" ||
                              currentMenu === "INDUSIND Report"
                                ? "subActive"
                                : ""
                            }`}
                          >
                            <div className="d-grid w-100">
                              <div
                                onClick={() => {
                                  currentMenu ===
                                    "Mandate Reg. Revarsal SFTP" ||
                                  currentMenu === "HDFC" ||
                                  currentMenu === "INDUSIND Report"
                                    ? setCurrentMenu("")
                                    : setCurrentMenu(
                                        "Mandate Reg. Revarsal SFTP"
                                      );
                                }}
                                className="w-100 d-flex justify-content-between"
                              >
                                <div className="d-flex gap-2">
                                  <CheckListIcon />
                                  <span className="menu-name">
                                    Mandate Reg. Revarsal SFTP
                                  </span>
                                </div>
                                {currentMenu === "Mandate Reg. Revarsal SFTP" ||
                                currentMenu === "HDFC" ||
                                currentMenu === "INDUSIND Report" ? (
                                  <UpIcon />
                                ) : (
                                  <DownIcon />
                                )}
                              </div>
                              <Collapse
                                in={
                                  currentMenu ===
                                    "Mandate Reg. Revarsal SFTP" ||
                                  currentMenu === "HDFC" ||
                                  currentMenu === "INDUSIND Report"
                                }
                              >
                                <div>
                                  <ul className="nav-sub-menu">
                                    {accessData.includes(
                                      "Mandate_Reg_Reversal_File_HDFC"
                                    ) && (
                                      <Nav.Item
                                        as="li"
                                        onClick={() => setCurrentMenu("HDFC")}
                                      >
                                        <Nav.Link
                                          eventKey={"HDFC"}
                                          as={Link}
                                          to={
                                            ROUTE_PATH.DEBIT_TRANSACTION_REPORTS_MANDATE_REGISTER_HDFC_REPORT
                                          }
                                          bsPrefix={`nav-list-item ${
                                            currentMenu === "HDFC"
                                              ? "subActive"
                                              : ""
                                          }`}
                                        >
                                          <div className="d-flex gap-2">
                                            <CheckListIcon />
                                            <span className="menu-name">
                                              HDFC
                                            </span>
                                          </div>
                                        </Nav.Link>
                                      </Nav.Item>
                                    )}
                                    {accessData.includes(
                                      "Mandate_Reg_Reversal_File_INDUS"
                                    ) && (
                                      <Nav.Item
                                        as="li"
                                        onClick={() =>
                                          setCurrentMenu("INDUSIND Report")
                                        }
                                      >
                                        <Nav.Link
                                          eventKey={"INDUSIND Report"}
                                          as={Link}
                                          to={
                                            ROUTE_PATH.DEBIT_TRANSACTION_REPORTS_MANDATE_REGISTER_INDUSLAND_REPORT
                                          }
                                          bsPrefix={`nav-list-item ${
                                            currentMenu === "INDUSIND Report"
                                              ? "subActive"
                                              : ""
                                          }`}
                                        >
                                          <div className="d-flex gap-2">
                                            <CheckListIcon />
                                            <span className="menu-name">
                                              INDUSIND Report
                                            </span>
                                          </div>
                                        </Nav.Link>
                                      </Nav.Item>
                                    )}
                                  </ul>
                                </div>
                              </Collapse>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      )}
                      {accessData.includes(
                        "Scan_Merge_status" || "Scan_SFTP_Merge_Status"
                      ) && (
                        <Nav.Item as="li">
                          <Nav.Link
                            eventKey={"Scanbase Reports"}
                            bsPrefix={`nav-list-item ${
                              currentMenu === "Scanbase Reports" ||
                              currentMenu === "Scan Merge Status" ||
                              currentMenu === "Scan Sftp Merge Status"
                                ? "subActive"
                                : ""
                            }`}
                          >
                            <div className="d-grid w-100">
                              <div
                                onClick={() => {
                                  currentMenu === "Scanbase Reports" ||
                                  currentMenu === "Scan Merge Status" ||
                                  currentMenu === "Scan Sftp Merge Status"
                                    ? setCurrentMenu("")
                                    : setCurrentMenu("Scanbase Reports");
                                }}
                                className="w-100 d-flex justify-content-between"
                              >
                                <div className="d-flex gap-2">
                                  <CheckListIcon />
                                  <span className="menu-name">
                                    Scanbase Reports
                                  </span>
                                </div>
                                {currentMenu === "Scanbase Reports" ||
                                currentMenu === "Scan Merge Status" ||
                                currentMenu === "Scan Sftp Merge Status" ? (
                                  <UpIcon />
                                ) : (
                                  <DownIcon />
                                )}
                              </div>
                              <Collapse
                                in={
                                  currentMenu === "Scanbase Reports" ||
                                  currentMenu === "Scan Merge Status" ||
                                  currentMenu === "Scan Sftp Merge Status" ||
                                  currentMenu === "Scan Sftp Merge log"
                                }
                              >
                                <div>
                                  <ul className="nav-sub-menu">
                                    <Nav.Item
                                      as="li"
                                      onClick={() =>
                                        setCurrentMenu("Scan Merge Status")
                                      }
                                    >
                                      <Nav.Link
                                        eventKey={"Scan Merge Status"}
                                        as={Link}
                                        to={ROUTE_PATH.SCAN_MERGE_STATUS}
                                        bsPrefix={`nav-list-item ${
                                          currentMenu === "Scan Merge Status"
                                            ? "subActive"
                                            : ""
                                        }`}
                                      >
                                        <div className="d-flex gap-2">
                                          <CheckListIcon />
                                          <span className="menu-name">
                                            Scan Merge Status
                                          </span>
                                        </div>
                                      </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item
                                      as="li"
                                      onClick={() =>
                                        setCurrentMenu("Scan Sftp Merge Status")
                                      }
                                    >
                                      <Nav.Link
                                        eventKey={"Scan Sftp Merge Status"}
                                        as={Link}
                                        to={ROUTE_PATH.SCAN_SFTP_MERGE_STATUS}
                                        bsPrefix={`nav-list-item ${
                                          currentMenu ===
                                          "Scan Sftp Merge Status"
                                            ? "subActive"
                                            : ""
                                        }`}
                                      >
                                        <div className="d-flex gap-2">
                                          <CheckListIcon />
                                          <span className="menu-name">
                                            Scan Sftp Merge Status
                                          </span>
                                        </div>
                                      </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item
                                      as="li"
                                      onClick={() =>
                                        setCurrentMenu("Scan Sftp Merge log")
                                      }
                                    >
                                      <Nav.Link
                                        eventKey={"Scan Sftp Merge log"}
                                        as={Link}
                                        to={ROUTE_PATH.SCAN_SFTP_MERGE_LOG}
                                        bsPrefix={`nav-list-item ${
                                          currentMenu === "Scan Sftp Merge log"
                                            ? "active"
                                            : ""
                                        }`}
                                      >
                                        <div className="d-flex gap-2">
                                          <CheckListIcon />
                                          <span className="menu-name">
                                            Scan Sftp Merge log
                                          </span>
                                        </div>
                                      </Nav.Link>
                                    </Nav.Item>
                                  </ul>
                                </div>
                              </Collapse>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      )}
                      {accessData.includes("Payment_Logs") && (
                        <Nav.Item
                          as="li"
                          onClick={() => setCurrentMenu("Payment Logs")}
                        >
                          <Nav.Link
                            eventKey={"Payment Logs"}
                            as={Link}
                            to={ROUTE_PATH.PAYMENT_LOGS}
                            bsPrefix={`nav-list-item ${
                              currentMenu === "Payment Logs" ? "subActive" : ""
                            }`}
                          >
                            <div className="d-flex gap-2">
                              <CheckListIcon />
                              <span className="menu-name">Payment Logs</span>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      )}
                      {accessData.includes("Provision_Analysis_Report") && (
                        <Nav.Item
                          as="li"
                          onClick={() => setCurrentMenu("Open Mandate Reports")}
                        >
                          <Nav.Link
                            eventKey={"Open Mandate Reports"}
                            as={Link}
                            to={ROUTE_PATH.OPEN_MANDATE_REPORT}
                            bsPrefix={`nav-list-item ${
                              currentMenu === "Open Mandate Reports"
                                ? "subActive"
                                : ""
                            }`}
                          >
                            <div className="d-flex gap-2">
                              <CheckListIcon />
                              <span className="menu-name">
                                Open Mandate Reports
                              </span>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      )}
                      {/* {accessData.includes("MERGER_SMS") && (
                        <Nav.Item as="li" onClick={() => setCurrentMenu("Merger_SMS")}>
                          <Nav.Link
                            eventKey={"Merger SMS"}
                            as={Link}
                            to={ROUTE_PATH.MERGER_SMS}
                            bsPrefix={`nav-list-item ${
                              currentMenu === "Merger_SMS" ? "subActive" : ""
                            }`}>
                            <div className="d-flex gap-2">
                              <CheckListIcon />
                              <span className="menu-name">Merger SMS</span>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      )} */}
                    </ul>
                  </div>
                </Collapse>
              </div>
            </Nav.Link>
          </Nav.Item>
        )}
        {(accessData.includes("PAYMENT_SEARCH") || accessData.includes("BANKING_NOTIFICATION")) && (
          <Nav.Item as="li">
            <Nav.Link eventKey={"apiReports"} bsPrefix="nav-list-item">
              <div className="d-grid w-100">
                <div
                  onClick={() => {
                    closeRemainingTabs("apiReports");
                    setCurrentMenu("apiReports");
                  }}
                  className="w-100 d-flex justify-content-between"
                >
                  <div className="d-flex gap-2">
                    <PayableIcon />
                    <span className="menu-name">Contract Overview</span>
                  </div>
                  {reports ? <UpIcon /> : <DownIcon />}
                </div>
                <Collapse in={apiReports}>
                  <div>
                    <ul className="nav-sub-menu">
                    {accessData.includes("PAYMENT_SEARCH") && (
                        <Nav.Item
                          as="li"
                          onClick={() => setCurrentMenu('view_contract_overview')}
                        >
                          <Nav.Link
                            eventKey={"API Reports"}
                            as={Link}
                            to={ROUTE_PATH.PAYMENT_API_REPORTS}
                            bsPrefix={`nav-list-item ${
                              currentMenu === "view_contract_overview"
                                ? "subActive"
                                : ""
                            }`}
                          >
                            <div className="d-flex gap-2">
                              <CheckListIcon />
                              <span className="menu-name">
                                Current Contract No. Status
                              </span>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                    )}
                    {
                      accessData.includes('BANKING_NOTIFICATION') &&
                      <Nav.Item 
                      as="li"
                      onClick={() => setCurrentMenu("banking_team_notify")}
                      >
                          <Nav.Link
                            eventKey={"banking_team_notify"}
                            as={Link}
                            to={ROUTE_PATH.BANKING_TEAM_NOTIFICATION}
                            bsPrefix={`nav-list-item ${
                              currentMenu === "banking_team_notify"
                              ? "subActive"
                              : ""
                              }`}
                          >
                            <div className="d-flex gap-2">
                              <CheckListIcon />
                              <span className="menu-name">
                                  Banking Team Notification
                              </span>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                  }
                    </ul>
                  </div>
                </Collapse>
              </div>
            </Nav.Link>
          </Nav.Item>
        )}
        {(accessData.includes("Standalone")) && (
          <Nav.Item as="li">
            <Nav.Link eventKey={"standalone"} bsPrefix="nav-list-item">
              <div className="d-grid w-100">
                <div
                  onClick={() => {
                    closeRemainingTabs("standalone");
                    setCurrentMenu("standalone");
                  }}
                  className="w-100 d-flex justify-content-between"
                >
                  <div className="d-flex gap-2">
                    <PayableIcon />
                    <span className="menu-name">Standalone</span>
                  </div>
                  {reports ? <UpIcon /> : <DownIcon />}
                </div>
                <Collapse in={standalone}>
                  <div>
                    <ul className="nav-sub-menu">
                        <Nav.Item
                          as="li"
                          onClick={() => setCurrentMenu('standalone_file_upload')}
                        >
                          <Nav.Link
                            eventKey={"standalone_file_upload"}
                            as={Link}
                            to={ROUTE_PATH.STANDALONE_FILE_UPLOAD}
                            bsPrefix={`nav-list-item ${
                              currentMenu === "standalone_file_upload"
                                ? "subActive"
                                : ""
                            }`}
                          >
                            <div className="d-flex gap-2">
                              <CheckListIcon />
                              <span className="menu-name">
                                File Upload
                              </span>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item
                          as="li"
                          onClick={() => setCurrentMenu('standalone_file_status')}
                        >
                          <Nav.Link
                            eventKey={"standalone_file_status"}
                            as={Link}
                            to={ROUTE_PATH.STANDALONE_FILE_STATUS}
                            bsPrefix={`nav-list-item ${
                              currentMenu === "standalone_file_status"
                                ? "subActive"
                                : ""  
                            }`}
                          >
                            <div className="d-flex gap-2">
                              <CheckListIcon />
                              <span className="menu-name">
                                File Status
                              </span>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                    </ul>
                  </div>
                </Collapse>
              </div>
            </Nav.Link>
          </Nav.Item>
        )}
      </Nav>
    </div>
  );
}

export default Routes
